import React from 'react';
import { Addon, Supplement } from 'modules/ReservationsList/types';
import CurrencyFormatter from 'components/shared/CurrencyFormatter';

const AddonsList = ({ addons }: { addons: Addon[] }) => (
  <ul className="addonsList">
    {addons.map((addon) => {
      const { addonName = '', addonPrice = 0, addonQuantity = 0 } = addon || {};
      return (
        <li className="productAddon" key={`${addonName}`}>
          <span>
            {addonQuantity} x {addonName}
          </span>
          <span>
            <CurrencyFormatter amount={addonPrice} />
          </span>
        </li>
      );
    })}
  </ul>
);

const SupplementItem = ({ supplement }: { supplement: Supplement }) => {
  const { name = '', quantity = 0, amount = 0, addons = [] } = supplement || {};
  const formattedPrice = <CurrencyFormatter amount={amount || 0} />;

  return (
    <li className="product" key={`${name}`}>
      <div className="productMain">
        <span className="productTitle">
          {quantity} x {name}
        </span>
        <span className="productPrice">{formattedPrice}</span>
      </div>
      {addons?.length > 0 && <AddonsList addons={addons} />}
    </li>
  );
};

export default SupplementItem;
