import React, { memo } from 'react';
import classnames from 'classnames';
import { CHARGE_TYPES, PAYMENT_STATUS, REFUND_CHARGE_TYPES } from 'constants/charge';
import { getFeeKindLabel } from 'modules/Order/services';
import type { DepositPayableFee, PaymentInfo, Supplement } from 'modules/ReservationsList/types';
import CurrencyFormatter from 'components/shared/CurrencyFormatter';
import Panel from '../Panel';
import SupplementItem from './SupplementItem';
import './SupplementPanel.style.scss';

interface SupplementsPanelProps {
  supplements?: Supplement[];
  paymentInfo?: PaymentInfo | null;
  hideHeader?: boolean;
  depositPayableFee?: DepositPayableFee | null;
  showFeeDetails?: boolean;
  className?: string;
}

const SupplementsPanel = ({
  supplements = [],
  paymentInfo,
  hideHeader = false,
  depositPayableFee,
  showFeeDetails = true,
  className,
}: SupplementsPanelProps) => {
  // For cancellations backend requires us to get the total amount from `depositPayableFee`
  const totalAmount =
    (depositPayableFee?.payableType === CHARGE_TYPES.cancellation
      ? depositPayableFee.payableAmount
      : paymentInfo?.paymentAmount) || 0;

  const renderFee = ({
    label = '',
    fee: { amount = 0, kind = '', active = true, percentage = 0 } = {},
    isRefund = false,
  }: {
    label?: string;
    fee: {
      amount?: number | string;
      kind?: string;
      active?: boolean;
      percentage?: number;
    };
    isRefund?: boolean;
  }) => {
    const percentageText = (percentage && ` (${percentage}%)`) || '';
    const amountText = +amount <= 0 ? 'Free' : <CurrencyFormatter amount={amount || 0} />;
    return (
      (active && (
        <div className={classnames('fee', { refund: isRefund })}>
          <span>
            {label}
            {percentageText}
            {kind ? getFeeKindLabel(kind) : ''}
          </span>
          <span className="feeAmount">{amountText}</span>
        </div>
      )) ||
      null
    );
  };

  const paymentCharge = paymentInfo?.paymentCharge;
  const paymentTax = paymentInfo?.paymentTax;

  return (
    (supplements.length && (
      <Panel
        header={
          <>
            <div>
              Rsv payment
              {(paymentInfo?.paymentDate && `, ${paymentInfo.paymentDate}`) || ''}
            </div>
            {paymentInfo?.secureId && <div>#{paymentInfo?.secureId}</div>}
          </>
        }
        className={classnames({ 'hide-header': hideHeader })}
      >
        <section className={classnames('supplement-panel', className)}>
          <div className="products">
            <ul>
              {supplements.map((supplement) => (
                <SupplementItem key={supplement.name} supplement={supplement} />
              ))}
            </ul>
          </div>
          {showFeeDetails && (
            <>
              {renderFee({
                label: paymentCharge?.name || 'Service charge',
                fee: {
                  ...paymentCharge,
                  active: Boolean(paymentCharge?.active && +(paymentCharge?.amount || 0) > 0),
                },
              })}
              {renderFee({
                label: paymentTax?.name || 'Sales tax',
                fee: {
                  ...paymentTax,
                  active: Boolean(paymentTax?.active && +(paymentTax?.amount || 0) > 0),
                },
              })}
              {renderFee({
                label: 'Deposit',
                fee: {
                  amount: paymentInfo?.deposit,
                  active: CHARGE_TYPES.deposit === depositPayableFee?.payableType,
                },
              })}
              {renderFee({
                label: 'Cancellation fee',
                fee: {
                  amount: depositPayableFee?.cancellationFee,
                  active: CHARGE_TYPES.cancellation === depositPayableFee?.payableType,
                },
              })}
              <div className="subTotal">
                <span>Total</span>
                <span>
                  <CurrencyFormatter amount={totalAmount} />
                </span>
              </div>
              {!!paymentInfo?.refundAmount &&
                // TODO: Check with BE why `refundAmount` is set after successful cancellation charge
                // @ts-expect-error TS(2339): Property 'payableType' does not exist on type 'obj... Remove this comment to see the full error message
                REFUND_CHARGE_TYPES.includes(depositPayableFee?.payableType) &&
                renderFee({
                  label: 'Refunded',
                  fee: { amount: paymentInfo?.refundAmount },
                  isRefund: true,
                })}
              {/* After successful cancellation `cancelAmount` will contain the amount charged */}
              {!!depositPayableFee?.cancelAmount &&
                depositPayableFee?.payableType === CHARGE_TYPES.cancellation &&
                depositPayableFee?.paymentStatus === PAYMENT_STATUS.success &&
                renderFee({
                  label: 'Charged',
                  fee: { amount: depositPayableFee?.cancelAmount },
                  isRefund: true,
                })}
            </>
          )}
        </section>
      </Panel>
    )) ||
    null
  );
};

export default memo(SupplementsPanel);
