import React from 'react';
import type { SVGProps } from 'react';
import { COLORS } from 'constants/colors';

type Props = SVGProps<SVGSVGElement> & {
  circleBgColor?: string;
};

function AddPersonIcon({
  width = 24,
  height = 25,
  color = COLORS.WHITE,
  circleBgColor = COLORS.GREEN_PRIMARY,
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M11.234 12.4752H11.1151C10.6171 12.4845 10.1232 12.3831 9.66922 12.1781C9.21524 11.9732 8.81249 11.6699 8.49012 11.2902C7.06023 9.61071 7.28805 6.75794 7.31992 6.43837C7.41323 4.35224 8.36649 3.36166 9.15413 2.89903C9.76345 2.54954 10.4539 2.36614 11.1563 2.36719H11.22C11.9249 2.36957 12.6167 2.55728 13.2261 2.91147C13.999 3.37177 14.9367 4.35691 15.0346 6.43448C15.065 6.75483 15.2873 9.61382 13.8637 11.2863C13.5404 11.6664 13.1369 11.9702 12.6822 12.1757C12.2275 12.3813 11.733 12.4835 11.234 12.4752ZM11.1524 3.41998C10.3414 3.41998 8.44113 3.72088 8.31906 6.50446C8.31942 6.5086 8.31942 6.51276 8.31906 6.5169C8.3185 6.52285 8.3185 6.52884 8.31906 6.53478C8.31906 6.56044 8.06169 9.21184 9.24433 10.5951C9.47983 10.8697 9.77467 11.0872 10.1066 11.2312C10.4385 11.3752 10.7987 11.4419 11.1602 11.4263H11.1983C11.5603 11.4414 11.9211 11.3745 12.2537 11.2306C12.5863 11.0867 12.882 10.8694 13.1188 10.5951C14.2936 9.20951 14.0386 6.56122 14.0347 6.53478C14.031 6.52486 14.0297 6.51421 14.0308 6.50368C13.9158 3.87561 12.206 3.4433 11.1975 3.41998H11.1524Z"
        fill={color}
      />
      <path
        d="M18.9518 17.2301V17.2177C18.9518 17.1874 18.9518 17.1563 18.9518 17.122C18.9246 16.3624 18.8741 14.5958 17.2319 14.0298L17.1938 14.0181C16.0767 13.7067 15.0165 13.2187 14.0534 12.5727C13.9985 12.5338 13.9365 12.5062 13.8709 12.4915C13.8054 12.4768 13.7375 12.4753 13.6713 12.487C13.6052 12.4987 13.542 12.5235 13.4854 12.5598C13.4289 12.5961 13.3801 12.6433 13.3419 12.6986C13.2638 12.8108 13.2331 12.9493 13.2565 13.084C13.28 13.2187 13.3557 13.3387 13.4671 13.4179C14.525 14.1339 15.6927 14.6725 16.924 15.0126C17.8058 15.3298 17.9045 16.2823 17.9309 17.1539C17.929 17.1858 17.929 17.2177 17.9309 17.2496C17.9384 17.645 17.9124 18.0404 17.8532 18.4314C15.7946 19.5065 13.4984 20.0456 11.1765 19.9989C8.85481 20.0406 6.55966 19.5001 4.50057 18.4267C4.43949 18.0359 4.41347 17.6404 4.42281 17.2449C4.42281 17.2146 4.42281 17.1835 4.42281 17.1493C4.44925 16.2776 4.548 15.3252 5.43361 15.0079C6.66359 14.6661 7.83018 14.1275 8.8882 13.4132C8.99914 13.3338 9.07438 13.2139 9.09766 13.0795C9.12094 12.945 9.09039 12.8068 9.01261 12.6948C8.97444 12.6392 8.92559 12.5918 8.86891 12.5554C8.81224 12.5189 8.74887 12.4941 8.68251 12.4824C8.61614 12.4706 8.5481 12.4722 8.48236 12.4871C8.41663 12.5019 8.3545 12.5297 8.29961 12.5688C7.33729 13.2168 6.27652 13.7049 5.15836 14.0142L5.12026 14.0259C3.47889 14.5958 3.42912 16.3624 3.4058 17.1197C3.40633 17.1524 3.40477 17.1852 3.40113 17.2177V17.2293C3.38024 17.8122 3.44574 18.3949 3.59552 18.9586C3.6332 19.0581 3.70225 19.1427 3.79223 19.1996C6.04345 20.4695 8.59831 21.1015 11.1819 21.0276C13.7643 21.0988 16.3174 20.467 18.5685 19.1996C18.6575 19.1416 18.7263 19.0574 18.7652 18.9586C18.9082 18.394 18.971 17.8122 18.9518 17.2301Z"
        fill={color}
      />
      <circle cx="17.6" cy="18.0992" r="6.4" fill={circleBgColor} />
      <rect x="13.9938" y="17.5645" width="7.168" height="1.024" rx="0.512" fill={color} />
      <rect
        x="17.0658"
        y="21.6602"
        width="7.168"
        height="1.024"
        rx="0.512"
        transform="rotate(-90 17.0658 21.6602)"
        fill={color}
      />
      <path
        d="M22.7646 15.014C22.9467 14.9052 23.1836 14.9642 23.2812 15.1525C23.9444 16.4309 24.1586 17.8998 23.8823 19.3209C23.5814 20.868 22.7203 22.2497 21.4639 23.2012C20.2074 24.1527 18.6441 24.6072 17.0734 24.4775C15.5027 24.3478 14.035 23.6431 12.9516 22.4985C11.8683 21.3538 11.2454 19.8496 11.2023 18.2741C11.1593 16.6986 11.6991 15.1626 12.7183 13.9605C13.7375 12.7583 15.1645 11.9745 16.7258 11.7592C18.1599 11.5615 19.6148 11.8562 20.8548 12.5887C21.0374 12.6965 21.0833 12.9363 20.9646 13.1121C20.846 13.2879 20.608 13.3331 20.4245 13.2267C19.342 12.5991 18.0774 12.3481 16.8307 12.52C15.4567 12.7095 14.201 13.3992 13.3041 14.4571C12.4072 15.515 11.9322 16.8667 11.9701 18.2531C12.0079 19.6395 12.5561 20.9632 13.5094 21.9705C14.4628 22.9779 15.7543 23.598 17.1366 23.7121C18.5188 23.8262 19.8945 23.4263 21.0002 22.589C22.1058 21.7516 22.8636 20.5357 23.1284 19.1743C23.3686 17.9389 23.1876 16.6625 22.6205 15.547C22.5244 15.358 22.5825 15.1228 22.7646 15.014Z"
        fill={color}
      />
    </svg>
  );
}

export default AddPersonIcon;
