import React from 'react';
import classnames from 'classnames';
import { COLORS } from 'constants/colors';
import { useIncomingCallActions } from 'modules/IncomingCall/hooks/useIncomingCallActions';
import type { IncomingCall } from 'modules/IncomingCall/types';
import SeatIcon from 'components/icons/statuses/SeatIcon';
import QueueIcon from 'components/icons/statuses/QueueIcon';
import styles from './Footer.module.scss';

type Props = {
  queue: Exclude<IncomingCall['queue'], null | undefined>;
  callback: () => void;
};

function QueueFooter({ queue, callback }: Props) {
  const { isFetchingQueue, handleSeatReservationFromQueue, handleViewQueue } =
    useIncomingCallActions({
      queueId: queue.id,
      dateTimeStart: queue.dateTimeStart,
      callback,
    });

  return (
    <>
      <button
        type="button"
        className={classnames(styles.action, styles.seated)}
        onClick={handleSeatReservationFromQueue}
        aria-label="seat"
        disabled={isFetchingQueue}
      >
        <SeatIcon width={20} color={COLORS.SEATED} />
        <span className={styles.label}>{isFetchingQueue ? 'Loading queue...' : 'Seat'}</span>
      </button>
      <button
        type="button"
        className={styles.action}
        onClick={handleViewQueue}
        aria-label="view queue"
      >
        <QueueIcon width={19} height={20} color={COLORS.BLACK} />
        <span className={styles.label}>View queue</span>
      </button>
    </>
  );
}

export default QueueFooter;
