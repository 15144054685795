import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectVenuePaymentProfiles,
  selectIsVenueInPaymentWarning,
  selectIsVenueAccessBlocked,
  selectPartnerId,
} from 'modules/Partner/selectors';
import { PUBLIC_ROUTES, ROUTES } from 'constants/routes';
import { APPLE_REVIEW_PARTNER_ID } from 'constants/app';
import { setVisiblePaymentWarning } from 'modules/Partner/actions';
import BlockedAccess from './BlockedAccess';
import AlertsContainer from '../AlertsContainer';
import useWarnings from 'modules/Partner/hooks/useWarnings';

const PaymentProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const venuePaymentProfile = useAppSelector(selectVenuePaymentProfiles);
  const isVenueInPaymentWarning = useAppSelector(selectIsVenueInPaymentWarning);
  const isVenueAccessBlocked = useAppSelector(selectIsVenueAccessBlocked);
  const partnerId = useAppSelector(selectPartnerId);
  const { isStripeWarningVisible, closeStripeWarning } = useWarnings();

  const hidePaymentProfile = () => {
    dispatch(setVisiblePaymentWarning(false));
  };

  const handleBillingPage = () => {
    history.push(`${ROUTES.SETTINGS}billing`);
  };

  // we need to hide everything related to payment for apple review account for ipad app.
  if (partnerId === APPLE_REVIEW_PARTNER_ID) {
    return null;
  }

  if ((PUBLIC_ROUTES as ReadonlyArray<string>).includes(location.pathname)) {
    return null;
  }

  if (isVenueAccessBlocked) {
    // we need to keep settings open when venue is blocked to perform billings and logout
    // the regex below matches any sub route of that start with '/settings/*'
    const regex = new RegExp(`${ROUTES.SETTINGS}?.*`, 'g');
    if (location.pathname.match(regex)?.length) {
      return null;
    }

    return (
      <BlockedAccess
        handleBillingPage={handleBillingPage}
        isVenueAccessBlocked={isVenueAccessBlocked}
      />
    );
  }

  const alerts = [
    {
      id: 'venue-payment-warning',
      messages: venuePaymentProfile?.warnings,
      buttonLabel: 'Go to billing',
      buttonAction: handleBillingPage,
      closeAction: hidePaymentProfile,
      isVisible: isVenueInPaymentWarning,
    },
    {
      id: 'stripe-warning',
      messages: [
        'There is an error with your Stripe account. Guests will not be able to make reservations with payment requirements or make any online purchases via your widget.<br/>Contact Stripe to solve the issues, and disable payments to ensure guests can make reservations in the mean time.',
      ],
      buttonLabel: 'Go to payments',
      buttonAction: () => history.push(`${ROUTES.SETTINGS}?page=payment-settings`),
      closeAction: () => closeStripeWarning(),
      isVisible: isStripeWarningVisible,
    },
  ];

  return <AlertsContainer paymentProfileAlerts={alerts} />;
};

export default PaymentProfile;
