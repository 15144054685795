import moment, { Moment } from 'moment-timezone';
import {
  DATE_FORMAT,
  DAY_START_OFFSET,
  SERVER_DATE_TIME,
  SERVER_TIME,
  TIME_SLOT_FORMAT,
} from '../constants/time-and-date';
import { format as dateFnsFormat } from 'date-fns';

export const formatCalendarDate = (date: any, smallMobile: any) => {
  // ensure the date is displayed with today, yesterday and tomorrow
  const defaultFormat = 'ddd, D MMM';
  const midnight = moment().hour(0);
  const sixAM = moment().hour(6);
  const isTimeBetweenMidnightAndSixAM = moment().isBetween(midnight, sixAM, 'hour', '[)');
  const localeDate = date.locale(moment.locale());

  if (isTimeBetweenMidnightAndSixAM) {
    // We subtract two days here because after midnight, our system still considers "yesterday" as "today."
    //  therefore our yesterday become two days from the current time(This only apply after midnight and before 6am)
    if (moment(date).isSame(moment().subtract(2, 'day'), 'day')) {
      return `${smallMobile ? 'YDA' : 'Yesterday'}, ${localeDate.format(defaultFormat)}`;
    }

    if (moment(date).add(1, 'day').isSame(moment(), 'day')) {
      return `${smallMobile ? 'TDA' : 'Today'}, ${localeDate.format(defaultFormat)}`;
    }

    if (moment(date).isSame(moment(), 'day')) {
      return `${smallMobile ? 'TMR' : 'Tomorrow'}, ${localeDate.format(defaultFormat)}`;
    }

    return localeDate.format(defaultFormat);
  }

  return localeDate.calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: defaultFormat,
    lastDay: `${smallMobile ? `[YDA], ${defaultFormat}` : `[Yesterday], ${defaultFormat}`}`,
    sameDay: `${smallMobile ? `[TDA], ${defaultFormat}` : `[Today], ${defaultFormat}`}`,
    nextDay: `${smallMobile ? `[TMR], ${defaultFormat}` : `[Tomorrow], ${defaultFormat}`}`,
    nextWeek: defaultFormat,
    sameElse: () => defaultFormat,
  });
};

export const formatDateToServerDate = (date: any) => moment(date).format(DATE_FORMAT);

export const formatDateToServerDateTime = (date: any, format = SERVER_DATE_TIME) =>
  moment(date).format(format);

export const formatServerDate = (date: any, desiredFormat = 'll', format = SERVER_DATE_TIME) =>
  moment(date, format).format(desiredFormat);

export const formatServerTime = (
  time: any,
  desiredFormat = 'LT',
  { hasDate } = { hasDate: false }
) => {
  if (hasDate) {
    return moment(time, SERVER_DATE_TIME).format(desiredFormat);
  }
  return moment(time, SERVER_TIME).format(desiredFormat);
};

export const formatTimeSlotTime = (time: any, format = 'LT') =>
  moment(time, TIME_SLOT_FORMAT).format(format);

export const formatWithEndTime = (time: any, turnTime: any) =>
  `${moment(time, TIME_SLOT_FORMAT).format('LT')} - ${moment(time, TIME_SLOT_FORMAT)
    .add(turnTime, 'minutes')
    .format('LT')}`;

export const subtractFromDate = (date: any, number: any, period: any) =>
  moment(date).subtract(number, period);

export const addToDate = (date: any, number: any, period: any) => moment(date).add(number, period);

export const getCurrentDateAndTime = () => {
  const currentTime = moment();
  const sixAM = moment().hour(6);
  return currentTime.isBefore(sixAM) ? currentTime.subtract(1, 'day') : currentTime;
};

export const formatNumberToTime = (num: any, timeUnit: any, format = 'LT') => {
  const duration = moment.duration(num, timeUnit);
  return moment.utc(duration.as('milliseconds')).format(format);
};

export const formatNumberToHours = (num: any, timeUnit: any) => {
  const duration = moment.duration(num, timeUnit);
  const hours = duration.asHours();
  return `${hours === 1 ? '1 hour' : `${hours} hours`}`;
};

export const formatTimeToNumber = (time = '', timeUnit = 'minutes') =>
  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  moment.duration(formatServerTime(time, SERVER_TIME[0])).as(timeUnit);

const getDayDifference = (date: any) =>
  moment(date).startOf('day').diff(moment().startOf('day'), 'days');

export const getYearsOptions = (prevYearCount: any, nextYearCount: any) => {
  const currentYear = getCurrentDateAndTime().year();
  return [
    ...Array.from(Array(prevYearCount).keys(), (n) => currentYear - (n + 1))
      .map((y) => ({ label: y, value: y }))
      .reverse(),
    { label: currentYear, value: currentYear },
    ...Array.from(Array(nextYearCount).keys(), (n) => n + 1 + currentYear).map((y) => ({
      label: y,
      value: y,
    })),
  ];
};

export const getMonthOptions = () => moment.months().map((label, value) => ({ label, value }));

export const createMonthRange = (date: any, datesToIgnore = [], behind = 2, ahead = 3) => {
  const startDate = subtractFromDate(date || getCurrentDateAndTime(), behind, 'month');
  const endDate = addToDate(date || getCurrentDateAndTime(), ahead, 'month');
  const range = [];
  const rangeStart = moment(startDate);
  while (rangeStart.isSameOrBefore(endDate)) {
    const currentDate = moment(rangeStart);
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    if (!datesToIgnore.includes(currentDate.format('YYYY-MM'))) {
      range.push(currentDate);
    }
    rangeStart.add(1, 'month');
  }
  return range;
};

// not using moment isBetween here because it also compares date.
const isTimeAfterMidnight = (time: any, inFormat = SERVER_DATE_TIME) => {
  const start = moment.duration(moment(time, inFormat).format(SERVER_TIME[0])).asMinutes();
  return start >= 0 && start < DAY_START_OFFSET * 60;
};

// to "add" or "subract" action in moment for 00:00 to 06:00
export const adjustDayAfterMidNight = ({
  time,
  inFormat = SERVER_DATE_TIME,
  action = 'add',
  day = 1,
}: {
  time: string | Moment;
  inFormat?: string;
  action?: 'add' | 'subtract';
  day?: number;
}): Moment => {
  const momentTime = moment(time, inFormat);
  if (isTimeAfterMidnight(time, inFormat) && ['add', 'subtract'].includes(action)) {
    return momentTime[action](day, 'day');
  }
  return momentTime;
};

export const isToday = (date: string | Moment) =>
  formatServerDate(date, DATE_FORMAT) === formatServerDate(moment(), DATE_FORMAT);

// NOTE: getCurrentDateTime using 6 to 6 time,This is used for checking  footerDate,
//       where footerDate is already handled for to adjust midnight time
export const isTodayWithinSixToSix = (date: string | Moment) =>
  formatServerDate(date, DATE_FORMAT) === formatServerDate(getCurrentDateAndTime(), DATE_FORMAT);

// Used to 6 to 6 check from date from api
export const isTodayWithMidnight = (dateString: string, format: string) => {
  const momentTime = adjustDayAfterMidNight({
    time: dateString,
    inFormat: format,
    action: 'subtract',
  });
  return moment(momentTime).isSame(getCurrentDateAndTime(), 'day');
};

// NOTE: Do not use this method to compare footer date, but only dateTime thats coming from apis
export const isCurrentOrPreviousDay = (dateTime = '') => {
  // checking if dateTime of a reservation from api is in midnight and current datetime is before midnight
  // then it should be compared with adding a day too see if its in current or previous day
  // since the listing apis will from backend gives reservations with next day into previous day for 6 to 6.
  if (isTimeAfterMidnight(dateTime) && !isTimeAfterMidnight(moment())) {
    return moment(dateTime).isSameOrBefore(moment().add(1, 'day'), 'day');
  }
  return moment(dateTime).isSameOrBefore(moment(), 'day');
};

// Get short weekday names starting at Monday
// Moment only provides list starting at Sunday or based on locale
export const getWeekdaysShort = () => {
  const days = moment.weekdaysShort();
  return [...days.slice(1), days[0]];
};

export const formattedCountDownTimer = (time: any, isQueue: any, format = 'H:mm') => {
  // conditional rendering hours
  const timeFormat = isQueue && time.asHours() < 1 ? 'mm:ss' : format;
  return moment.utc(time.asMilliseconds()).format(timeFormat);
};

// check slot time with footer in 6 am to 6 am here
export const checkIsItemInFooterDate = (footerDate: any, startTime: any) => {
  const fDate = footerDate?.clone();
  const isItemInFooterDate = formatDateToServerDate(fDate) === formatDateToServerDate(startTime);
  // since footer date is 6 to 6 now, and  if item (reservation, waitlist, queue) time is after midnight
  // then its slot date changes to next day in apis from backend, so in order to check if item is in the footer date
  // we need to add 1 day to footer date to check with slot start time
  if (isTimeAfterMidnight(startTime)) {
    return formatDateToServerDate(fDate.add(1, 'day')) === formatDateToServerDate(startTime);
  }
  return isItemInFooterDate;
};

// Creates array of options from 6:00 to 6:00 at 15 minute intervals
// selectedStartDate is DATE_FORMAT string
export const createTimeSelectOptions = (selectedStartDate: any, interval = 15) => {
  const options = [];
  for (let hour = 6; hour < 30; hour += 1) {
    for (let minute = 0; minute < 60; minute += interval) {
      const date = selectedStartDate
        ? adjustDayAfterMidNight({
            time: moment(selectedStartDate).set({
              hour: moment().get('hour'),
              minute: moment().get('minute'),
              second: 0,
              millisecond: 0,
            }),
            inFormat: DATE_FORMAT,
            action: 'subtract',
          })
        : getCurrentDateAndTime();
      date.set({ hour: hour % 24, minute });

      // When after midnight 0:00 - 5:59 an extra hour needs to be added
      if (hour > 23) {
        date.add(1, 'day');
      }
      // When no selectedStartTime is provided return all times 6:00 to 6:00
      // Otherwise only add times after the current datetime
      if (!selectedStartDate || date.isAfter(moment())) {
        options.push({
          date,
          value: date.format(),
          label: date.format('LT'),
        });
      }
    }
  }
  return options;
};

// Get locale separator from moment
// It's always either : or .
export const getTimeSeparator = () =>
  /:/.test(moment.localeData().longDateFormat('LT')) ? ':' : '.';

export const determineFunnelDateOnEdit = (isTimeEdit: any, slotStartTime: any) => {
  if (isTimeEdit && getDayDifference(slotStartTime) < 0) {
    return null;
  }
  if (isTimeAfterMidnight(slotStartTime)) {
    return moment(slotStartTime).subtract(1, 'day').format(DATE_FORMAT);
  }
  return moment(slotStartTime).format(DATE_FORMAT);
};

// Due to difference between venue default timezone and current timezone, we are overriding datePicker day classes
export const getDayClasses = (date: any, selected: string) => {
  if (dateFnsFormat(date, 'yyyy-MM-dd') === selected) {
    return 'umai__custom--selected';
  }

  if (getCurrentDateAndTime().format(DATE_FORMAT) === dateFnsFormat(date, 'yyyy-MM-dd')) {
    return 'umai__custom--today';
  }

  return '';
};
