import React from 'react';
import {
  ACTIVE_QUEUE_STATUSES,
  PRE_SEATED_STATUSES,
  STATUSES,
} from 'modules/ReservationsList/constants';
import type { IncomingCall } from 'modules/IncomingCall/types';
import Status from './Status';
import styles from './Content.module.scss';

type Props = {
  data: IncomingCall;
};

export default function DetailsHeader({ data }: Props) {
  const renderLabel = () => {
    if (data.waitlist) {
      return 'Upcoming waitlist';
    }

    if (data.queue) {
      return 'Upcoming queue';
    }

    if (data.upcomingReservation) {
      return 'Upcoming reservation';
    }

    if (data.lastReservation) {
      return 'Last visit';
    }

    return null;
  };

  const renderStatus = () => {
    if (data.waitlist) {
      return null;
    }

    if (data.queue && ACTIVE_QUEUE_STATUSES.includes(data.queue.status.toUpperCase())) {
      return <Status status={data.queue.status} />;
    }

    if (
      data.upcomingReservation &&
      PRE_SEATED_STATUSES.includes(data.upcomingReservation.status.toUpperCase())
    ) {
      return <Status status={data.upcomingReservation.status} />;
    }

    if (data.lastReservation && data.lastReservation.status.toUpperCase() === STATUSES['NO-SHOW']) {
      return <Status status={data.lastReservation.status} />;
    }

    return null;
  };

  return (
    <h6 className={styles.heading}>
      {renderLabel()}
      {renderStatus()}
    </h6>
  );
}
