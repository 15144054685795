import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FORMAT } from '@umai/common';
import { ROUTES } from 'constants/routes';
import { adjustDayAfterMidNight } from 'utils/date-and-time';
import { useAppDispatch } from 'hooks/redux';
import { changeDate } from 'modules/Footer/actions';

type ViewWaitlistParams = {
  dateTime: string;
  id: number;
};

export const useViewWaitlistDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return ({ dateTime, id }: ViewWaitlistParams) => {
    const waitlistDate = adjustDayAfterMidNight({ time: dateTime, action: 'subtract' });
    const params = new URLSearchParams(location.search);
    params.set('waitlistId', id.toString());
    params.set('date', waitlistDate.format(DATE_FORMAT));

    dispatch(changeDate(waitlistDate));

    // redirect to waitlist list with the existing search params
    history.push({
      ...location,
      pathname: ROUTES.WAITLIST,
      search: params.toString(),
      state: { isOpenDrawer: true, waitlistId: id },
    });
  };
};
