import React from 'react';
import { ROUTES } from 'constants/routes';
import classNames from 'classnames';
import CloseIcon from 'components/icons/CloseIcon';
import { useLocation } from 'react-router-dom';
import styles from './AlertsContainer.module.scss';

type Props = {
  buttonLabel: string;
  isReferral?: boolean;
  onButtonClick: () => void;
  onClose: () => void;
};

const AlertButtons = ({ buttonLabel, onButtonClick, onClose, isReferral }: Props) => {
  const location = useLocation();

  return (
    <div>
      {/* Do not show button if we already on settings app */}
      {!location.pathname.includes(ROUTES.SETTINGS) || isReferral ? (
        <div
          className={classNames(styles.actionButtonContainer, {
            [styles.referralButtonContainer]: isReferral,
          })}
        >
          {isReferral ? (
            <button type="button" onClick={onClose} className={styles.actionButtonNoThanks}>
              No thanks
            </button>
          ) : null}
          <button
            type="button"
            className={classNames(styles.actionButton, { [styles.referral]: isReferral })}
            onClick={onButtonClick}
          >
            {buttonLabel}
          </button>
        </div>
      ) : null}
      {!isReferral ? <CloseIcon className={styles.closeIcon} onClick={onClose} /> : null}
    </div>
  );
};

export default AlertButtons;
