import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { isMobileDevice } from 'utils/devices';
import { formatDateToServerDateTime, getCurrentDateAndTime } from 'utils/date-and-time';
import { DATE_FORMAT } from 'constants/time-and-date';
import { SOURCE_OF_RESERVATION } from 'modules/ReservationsList/constants';
import { RESERVATION_FUNNEL_STEPS, THIRD_PARTY_BOOKER_STEPS } from './constants';
import { isWaitlistCreation } from './services';
import * as ActionTypes from './action-types';
import type { ReservationFunnel } from './types';

const getInitialState = (): ReservationFunnel => ({
  id: null,
  date: isMobileDevice ? null : getCurrentDateAndTime().format(DATE_FORMAT), // YYYY-MM-DD
  assignableTables: {
    available: [],
    notAvailable: [],
    upcoming: [],
  },
  errorMessage: '',
  guest: {},
  guestQuery: '',
  notes: '',
  partySize: isMobileDevice ? null : 2,
  reservationSuccess: false,
  availabilityId: null,
  selectedTableIds: [],
  selectedTableIdsPreEdit: [],
  lockTables: [],
  availabilityAndSlots: [],
  step: RESERVATION_FUNNEL_STEPS.FIRST_STEP,
  previousStep: null,
  tables: [],
  timeSlot: null,
  turnTime: null,
  reservationLoading: false,
  notifylistId: null,
  notifyTimes: {},
  tagIds: [],
  isWalkin: false,
  isTemporaryGuest: false,
  isCustomTime: false,
  communication: {
    sms: null,
    email: null,
    smsPayment: null,
    emailPayment: null,
  },
  paymentExpireHours: null,
  isManualPayment: false,
  customCharge: null,
  isManualPaymentReactivation: false,
  manualPaymentUrl: null,
  isEditingGuest: false,
  venueId: null,
  calendarData: {},
  isEdit: false,
  isSlotsLoading: true,
  isTablesLoading: true,
  isChargingFeesLoading: true,
  isCalendarLoading: true,
  offlineWaitlistId: null,
  isQueue: false,
  isEditingQueue: false,
  isQueueOpen: false,
  template: {
    name: 'Default confirmation',
    id: null,
  },
  isReservationWithGuest: false,
  tablesCombinations: [],
  hasDateUpdated: false,
  chargingFees: null,
  thirdPartyBooker: {},
  thirdPartyBookerStep: THIRD_PARTY_BOOKER_STEPS.GUEST_SELECTION_STEP,
  idempotencyKey: uuidv4(),
  isSqueeze: false,
  lastCalendarFetchParams: '',
  sourceOfReservation: SOURCE_OF_RESERVATION.INHOUSE,
  status: '',
});

const toggleSelectTable = (tables: any, id: any) =>
  tables.includes(id) ? tables.filter((i: any) => i !== id) : [...tables, id];

// @ts-expect-error TS(7006): Parameter 'currentStep' implicitly has an 'any' ty... Remove this comment to see the full error message
const setStep = (currentStep, nextStep) => ({
  previousStep: currentStep,
  step: nextStep,
});

// TODO: depending on device or other ui events, logic handling should be moved
// in actions or services called from react components
// eslint-disable-next-line default-param-last
const reservationFunnelReducer = (state = getInitialState(), { type, payload }: any) => {
  switch (type) {
    case ActionTypes.ADD_NOTES:
      return { ...state, ...payload };
    case ActionTypes.SELECT_FUNNEL_STEP:
      return {
        ...state,
        ...setStep(state.step, payload),
      };
    case ActionTypes.SELECT_PARTY_SIZE: {
      // If there is not step in payload then for mobile device on walk-in and new reservation (new reservation mean no value in state.date):
      // we remain on the same page (state.step) and don't change to SELECT_TIME_SLOT step on party size change
      const step =
        payload.mobile && state.date && !state.isWalkin
          ? RESERVATION_FUNNEL_STEPS.SELECT_TIME_SLOT
          : state.step;
      return {
        ...state,
        step: payload.step || step,
        errorMessage: '',
        partySize: payload.partySize,
      };
    }
    case ActionTypes.SELECT_DATE: {
      const mobileStep = state.partySize ? RESERVATION_FUNNEL_STEPS.SELECT_TIME_SLOT : state.step;
      const step = payload.mobile ? mobileStep : RESERVATION_FUNNEL_STEPS.FIRST_STEP;
      return {
        ...state,
        step,
        errorMessage: '',
        date: payload.date,
        hasDateUpdated: true,
      };
    }
    case ActionTypes.SELECT_TIME_SLOT: {
      const { mobile, step, ...timeSlot } = payload;
      const mobileStep =
        step === RESERVATION_FUNNEL_STEPS.CONFIRM_RESERVATION
          ? step
          : RESERVATION_FUNNEL_STEPS.SELECT_TABLES;
      const nextStep = mobile ? mobileStep : RESERVATION_FUNNEL_STEPS.SELECT_GUEST;
      // When editing a reservation and a guest is selected: Skip guest step
      const isSkipGuest =
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        ((state.notifylistId || state.id) && state.guest?.id) ||
        state.isTemporaryGuest ||
        state.isReservationWithGuest;

      return {
        ...state,
        errorMessage: '',
        notifyTimes: {},
        ...timeSlot,
        ...setStep(state.step, isSkipGuest ? RESERVATION_FUNNEL_STEPS.SELECT_TABLES : nextStep),
      };
    }
    case ActionTypes.SELECTED_TABLES_TOGGLE: {
      return {
        ...state,
        errorMessage: '',
        selectedTableIds: toggleSelectTable(state.selectedTableIds, payload?.id),
      };
    }
    case ActionTypes.SELECT_GUEST: {
      const { mobile, ...guest } = payload || {};
      return {
        ...state,
        guest,
        isTemporaryGuest: false,
        ...setStep(
          RESERVATION_FUNNEL_STEPS.SELECT_GUEST,
          (mobile && !state.isWalkin) || isWaitlistCreation(state.notifyTimes)
            ? RESERVATION_FUNNEL_STEPS.CONFIRM_RESERVATION
            : RESERVATION_FUNNEL_STEPS.SELECT_TABLES
        ),
      };
    }
    case ActionTypes.SELECT_QUEUE_GUEST:
      return {
        ...state,
        guest: payload,
        isTemporaryGuest: false,
        reservationLoading: true,
      };
    case ActionTypes.GET_RESERVATION_SLOTS:
      return { ...state, availabilityAndSlots: payload, isSlotsLoading: false };
    case ActionTypes.GET_FUNNEL_TABLES:
      return { ...state, tables: payload };
    case ActionTypes.GET_ASSIGNABLE_TABLES:
      return { ...state, assignableTables: payload };
    case ActionTypes.CREATE_GUEST_FROM_QUERY:
      return {
        ...state,
        ...setStep(
          state.step,
          state.isQueue
            ? RESERVATION_FUNNEL_STEPS.CREATE_QUEUE_GUEST
            : RESERVATION_FUNNEL_STEPS.CREATE_GUEST
        ),
        guestQuery: payload.query,
      };
    case ActionTypes.CREATE_TEMPORARY_GUEST:
      return {
        ...state,
        guest: {},
        guestQuery: payload.query,
        isTemporaryGuest: true,
        ...setStep(
          state.step,
          payload.mobile
            ? RESERVATION_FUNNEL_STEPS.CONFIRM_RESERVATION
            : RESERVATION_FUNNEL_STEPS.SELECT_TABLES
        ),
      };
    case ActionTypes.CREATE_TEMPORARY_QUEUE_GUEST:
      return {
        ...state,
        guest: {},
        guestQuery: payload.query,
        isTemporaryGuest: true,
        reservationLoading: true,
      };
    case ActionTypes.CREATE_RESERVATION:
      return {
        ...state,
        reservationSuccess: true,
        reservationLoading: false,
      };
    case ActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SET_TURN_TIME:
      return {
        ...state,
        errorMessage: '',
        ...payload,
      };
    case ActionTypes.RESET_RESERVATION_FUNNEL:
      return getInitialState();
    case ActionTypes.EDIT_RESERVATION:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SET_RESERVATION_LOADING:
      return {
        ...state,
        reservationLoading: payload,
      };
    case ActionTypes.SET_NOTIFY_TIME:
      return {
        ...state,
        notifyTimes: payload,
        ...setStep(
          state.step,
          !state.partySize ? state.step : RESERVATION_FUNNEL_STEPS.SELECT_GUEST
        ),
        timeSlot: payload.startTime,
      };
    case ActionTypes.SET_TAGS:
      return {
        ...state,
        tagIds: [...payload],
      };
    case ActionTypes.START_WALKIN:
      return {
        ...state,
        isWalkin: true,
        timeSlot: payload.date,
        step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
        partySize: null,
      };
    case ActionTypes.START_QUEUE_WALKIN:
      return {
        ...state,
        isWalkin: true,
        timeSlot: payload.date || formatDateToServerDateTime(moment()),
        step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
        partySize: payload.partySize || null,
        ...payload,
      };
    case ActionTypes.GOTO_PREVIOUS_STEP:
      return {
        ...state,
        step: state.previousStep || null,
        previousStep: state.step,
      };
    case ActionTypes.SET_COMMUNICATION:
      return {
        ...state,
        communication: {
          ...state.communication,
          ...payload,
        },
      };
    case ActionTypes.SET_MANUAL_PAYMENT:
      return {
        ...state,
        customCharge: {
          ...state.customCharge,
          ...payload.customCharge,
        },
        isManualPayment: payload.isManualPayment,
      };
    case ActionTypes.SET_PAYMENT_EXPIRE_HOURS:
      return {
        ...state,
        paymentExpireHours: payload,
      };
    case ActionTypes.SET_EDITING_GUEST:
      return {
        ...state,
        isEditingGuest: payload,
      };
    case ActionTypes.SELECT_VENUE:
      return {
        ...state,
        venueId: payload,
        step: RESERVATION_FUNNEL_STEPS.FIRST_STEP,
      };
    case ActionTypes.START_CALENDAR_LOADING:
      return {
        ...state,
        ...(payload && { calendarData: {} }),
        isCalendarLoading: payload,
      };
    case ActionTypes.GET_CALENDAR_DATA:
      return {
        ...state,
        calendarData: { ...state.calendarData, ...payload.calendarData },
        // Payload might contain the date of the day with first available slots
        ...(payload.date &&
          state.step === RESERVATION_FUNNEL_STEPS.FIRST_STEP &&
          !state.isEdit &&
          // Only on the first fetch find the first available date from calendar data, once the date is updated, do not update it again.
          // For more info check getYearCalendarData
          !state.hasDateUpdated && { date: payload.date, hasDateUpdated: true }),
      };
    case ActionTypes.SET_LAST_FETCH_PARAMS:
      return {
        ...state,
        lastCalendarFetchParams: payload,
      };
    case ActionTypes.SET_SLOTS_LOADING:
      return { ...state, isSlotsLoading: payload };
    case ActionTypes.SET_TABLES_LOADING:
      return { ...state, isTablesLoading: payload };
    case ActionTypes.EDIT_QUEUE_GUEST:
      return {
        ...state,
        ...payload,
        isEditingQueue: true,
      };
    case ActionTypes.START_QUEUE:
      return {
        ...state,
        isQueue: true,
        step: RESERVATION_FUNNEL_STEPS.SELECT_QUEUE_PARTY_AND_GUEST,
        partySize: 2,
      };
    case ActionTypes.SET_SELECTED_TABLE_IDS:
      return {
        ...state,
        selectedTableIds: payload,
      };
    case ActionTypes.SET_LOCK_TABLES:
      return {
        ...state,
        lockTables: payload,
      };
    case ActionTypes.SET_RESERVATION_FUNNEL_GUEST:
      return {
        ...state,
        guest: payload,
      };
    case ActionTypes.SET_TEMPLATE:
      return {
        ...state,
        template: payload,
      };
    case ActionTypes.SET_RESERVATION_WITH_GUEST:
      return {
        ...state,
        isReservationWithGuest: true,
      };
    case ActionTypes.SET_FUNNEL_ON_DATE_CHANGE:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.GET_TABLES_COMBINATIONS:
      return {
        ...state,
        tablesCombinations: payload,
      };
    case ActionTypes.SET_CHARGING_FEES_LOADING:
      return {
        ...state,
        isChargingFeesLoading: payload,
      };
    case ActionTypes.SET_CHARGING_FEES:
      return {
        ...state,
        chargingFees: payload,
      };
    case ActionTypes.SET_THIRD_PARTY_BOOKER_STEP:
      return {
        ...state,
        thirdPartyBookerStep: payload,
      };
    case ActionTypes.CREATE_BOOKER_GUEST_FROM_QUERY:
      return {
        ...state,
        thirdPartyBookerStep: THIRD_PARTY_BOOKER_STEPS.CREATE_THIRD_PARTY_BOOKER,
        guestQuery: payload.query,
      };
    case ActionTypes.SELECT_THIRD_PARTY_GUEST:
      return {
        ...state,
        thirdPartyBooker: payload,
      };
    case ActionTypes.RESET_THIRD_PARTY:
      return {
        ...state,
        thirdPartyBooker: {},
      };
    case ActionTypes.RESET_TIME_SLOT_ON_DATE_CHANGE:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SET_QUEUE_OPEN:
      return {
        ...state,
        isQueueOpen: payload,
      };
    default:
      return state;
  }
};

export default reservationFunnelReducer;
