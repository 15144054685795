import React from 'react';
import type { IncomingCall, UpcomingKey } from 'modules/IncomingCall/types';
import GuestName from 'components/shared/GuestName';
import styles from './Content.module.scss';

type Props = {
  guest: IncomingCall['guest'];
  upcomingCall: IncomingCall[UpcomingKey] | undefined;
};

const Name = ({ guest, upcomingCall }: Props) => {
  const guestName = guest?.name ? guest.name.trim() : '';

  if (upcomingCall) {
    const upcomingCallName =
      `${upcomingCall.firstName ?? ''} ${upcomingCall.lastName ?? ''}`.trim();

    // Render guest name in the following order:
    // 1. If there is a upcoming reservation/queue/waitlist with a name, show it on top
    // 2. If there's a guest attached to it, and guest name is not same as reservation/queue/waitlist name, then show it
    // 3. Otherwise, if no guest, then its a temporary guest reservation/queue, then show 'Temporary guest'
    return (
      <span>
        {upcomingCallName.length ? (
          <GuestName className={styles.heading} name={upcomingCallName} />
        ) : null}
        {guest && guestName !== upcomingCallName ? (
          <GuestName
            name={guestName}
            isBlacklisted={guest.blacklisted}
            hasLoyalty={guest.hasLoyalty}
          />
        ) : null}
        {!guest ? <div>Temporary guest</div> : null}
      </span>
    );
  }

  if (guest && guestName.length) {
    return (
      <GuestName
        className={styles.heading}
        name={guestName}
        isBlacklisted={guest.blacklisted}
        hasLoyalty={guest.hasLoyalty}
      />
    );
  }

  return null;
};

export default Name;
