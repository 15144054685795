import React from 'react';
import classnames from 'classnames';
import { SERVER_DATE_TIME, useViewPortContext } from '@umai/common';
import { isTodayWithMidnight } from 'utils/date-and-time';
import styles from './Header.module.scss';

type Props = {
  slotStartTime: string | undefined;
  startTimeWithDate: string | undefined;
  isMissed: boolean;
};

const IsToday = ({ slotStartTime, startTimeWithDate, isMissed }: Props) => {
  const { mobile } = useViewPortContext();
  let isToday = false;
  let label = '';

  if (startTimeWithDate) {
    isToday = isTodayWithMidnight(startTimeWithDate, SERVER_DATE_TIME);
    label = 'Waitlist';
  }

  if (slotStartTime) {
    isToday = isTodayWithMidnight(slotStartTime, SERVER_DATE_TIME);
    label = 'Reservation';
  }

  if (isToday) {
    return (
      <h6 className={classnames(styles.today, { [styles.isMissed]: isMissed })}>
        {mobile ? 'Today' : `${label} today`}
      </h6>
    );
  }

  return null;
};

export default IsToday;
