import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FORMAT } from '@umai/common';
import { ROUTES } from 'constants/routes';
import { changeDate } from 'modules/Footer/actions';
import { adjustDayAfterMidNight } from 'utils/date-and-time';
import { useAppDispatch } from 'hooks/redux';

type ViewQueueParams = {
  dateTime: string;
  id: number;
};

export const useViewQueueDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return ({ dateTime, id }: ViewQueueParams) => {
    const queueDate = adjustDayAfterMidNight({ time: dateTime, action: 'subtract' });

    const params = new URLSearchParams(location.search);
    params.set('queueId', id.toString());
    params.set('date', queueDate.format(DATE_FORMAT));

    dispatch(changeDate(queueDate));

    // redirect to queue with the existing search params
    history.push({
      ...location,
      pathname: ROUTES.QUEUE_LIST,
      search: params.toString(),
      state: { isOpenDrawer: true, queueId: id },
    });
  };
};
