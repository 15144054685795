import type { VenueId } from 'modules/Venue/types';
import type { FilterIsUpcoming, FilterStatus } from './types';
import stylesFilter from './components/Filters/Filters.module.scss';

export const INCOMING_CALL_NOTIFICATION_ENABLED = (venueId: VenueId) =>
  `incoming-call-notification-enabed-${venueId}` as const;

export const DEFAULT_PAGE = 1;

export const PER_PAGE = 15 as const;

export const STATUS_FILTER: FilterStatus[] = [
  {
    label: 'Missed calls',
    value: 'ABANDON',
    isActive: false,
    className: stylesFilter.filterMissedCall,
  },
  {
    label: 'Incoming calls',
    value: 'SONNERIE',
    isActive: false,
    className: stylesFilter.filterIncomingCalls,
  },
];

export const IS_UPCOMING_FILTER: FilterIsUpcoming = {
  label: 'Upcoming',
  value: undefined,
  isActive: false,
  className: stylesFilter.filterIsUpcoming,
};
