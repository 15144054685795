import type { AppDispatch, AppGetState } from 'redux-store';
import debounce from 'lodash-es/debounce';
import { getPartnerSession } from 'utils/session';
import { showErrorMessage } from 'utils/alerts';
import { getErrorMessage } from 'utils/errors';
import { formatDateToServerDate, createMonthRange } from 'utils/date-and-time';
import { sortAvailabilitiesByStartTime } from 'modules/ReservationsList/services';
import venueApis from 'modules/Venue/apis/venue';
import reservationApis from 'modules/ReservationsList/apis';
import * as ActionTypes from './action-types';

export const changeAvailabilities =
  (availabilityId: any, availabilityName: any) => (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.CHANGE_AVAILABILITY,
      payload: { availabilityId, availabilityName },
    });
  };

const getReservationAvailabilitiesDebounce = debounce(
  async ({ venueId, footerDate, showLoader = false }, dispatch, { signal }) => {
    try {
      if (showLoader) dispatch({ type: ActionTypes.SET_AVAILABILITIES_LOADING, payload: true });
      const response = await venueApis.getVenueReservationAvailabilities(
        { venueId, date: (footerDate && formatDateToServerDate(footerDate)) || null },
        { signal }
      );
      dispatch({
        type: ActionTypes.GET_VENUE_AVAILABILITIES,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: sortAvailabilitiesByStartTime(response?.reservationAvailabilities || []),
      });
    } catch (error) {
      if (venueApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      if (showLoader) {
        dispatch({ type: ActionTypes.SET_AVAILABILITIES_LOADING, payload: false });
      }
    }
  },
  400
);

export const getReservationAvailabilities = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getReservationAvailabilitiesDebounce(params, dispatch, controller);
  return controller;
};

export const changeDate = (date: any) => ({
  type: ActionTypes.CHANGE_DATE,
  payload: date,
});

export const resetCalendarData = () => ({
  type: ActionTypes.RESET_CALENDAR_DATA,
  payload: {},
});

export const updateCalendarData = (data: any) => ({
  type: ActionTypes.UPDATE_CALENDAR_DATA,
  payload: data,
});

export const getCalendarData =
  (date: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const { calendarData } = getState().footer;
      // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      const monthRange = createMonthRange(date, Object.keys(calendarData));
      const { currentVenueId } = getPartnerSession();
      if (currentVenueId && monthRange?.length) {
        const response = await reservationApis.getCalendarReservations({
          venueId: currentVenueId,
          startDate: monthRange[0].format('YYYY-MM'),
          endDate: monthRange[monthRange.length - 1].format('YYYY-MM'),
        });
        dispatch(updateCalendarData(response || {}));
      }
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };
