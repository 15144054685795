import moment from 'moment-timezone';
import { getDataFromStorage, setDataToStorage, SERVER_DATE_TIME } from '@umai/common';
import type { VenueId } from 'modules/Venue/types';
import type {
  DefaultTemplateRequest,
  IncomingCallLog,
  IncomingCallStatus,
  IsUpcoming,
} from './types';
import type { GuestCommunicationCategory } from 'modules/Guest/types';
import { INCOMING_CALL_NOTIFICATION_ENABLED } from './constants';

export const incomingCallKeys = {
  fetchCalls: ({
    venueId,
    page,
    callType,
    isUpcoming,
  }: {
    venueId: VenueId;
    page: number;
    callType: IncomingCallStatus[];
    isUpcoming: IsUpcoming;
  }): [string, VenueId, number, IncomingCallStatus[], IsUpcoming] => [
    'incoming-call',
    venueId,
    page,
    callType,
    isUpcoming,
  ],

  fetchGuest: ({
    venueId,
    guestId,
  }: {
    venueId: VenueId;
    guestId?: number;
  }): [string, VenueId, number | undefined] => ['incoming-call-guest', venueId, guestId],

  fetchReservation: ({
    venueId,
    reservationId,
  }: {
    venueId: VenueId;
    reservationId?: number;
  }): [string, VenueId, number | undefined] => [
    'incoming-call-reservation',
    venueId,
    reservationId,
  ],

  fetchQueue: ({
    venueId,
    queueId,
  }: {
    venueId: VenueId;
    queueId?: number;
  }): [string, VenueId, number | undefined] => ['incoming-call-queue', venueId, queueId],

  fetchWaitlist: ({
    venueId,
    waitlistId,
  }: {
    venueId: VenueId;
    waitlistId?: number;
  }): [string, VenueId, number | undefined] => ['incoming-call-waitlist', venueId, waitlistId],

  sendDefaultSms: ({
    venueId,
    callLogId,
  }: {
    venueId: VenueId;
    callLogId: number;
  }): [string, VenueId, number] => ['incoming-call-send-default-sms', venueId, callLogId],

  fetchDefaultTemplates: ({
    venueId,
    category,
    details,
    communicationSettings,
    reminderSetting,
  }: DefaultTemplateRequest): [
    string,
    VenueId,
    GuestCommunicationCategory,
    boolean,
    boolean,
    boolean,
  ] => [
    'incoming-call-default-template',
    venueId,
    category,
    details,
    communicationSettings,
    reminderSetting,
  ],
};

function groupDateToFromNow(date: string, format = 'D MMM') {
  return moment(date, SERVER_DATE_TIME).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: format,
    lastDay: `[Yesterday]`,
    sameDay: `[Today]`,
    nextDay: `[Tomorrow]`,
    nextWeek: format,
    // when the date is further away
    sameElse: format,
  });
}

// Groups calls by date, so they can be displayed in the call logs by day
export const groupCallsByDate = (incomingCalls: IncomingCallLog[]) => {
  const groupedCalls = incomingCalls.reduce(
    (grouped: Record<string, IncomingCallLog[]>, call: IncomingCallLog) => {
      // Using date format as it's displayed in the UI
      const date = groupDateToFromNow(call.callDateMillis);

      return {
        ...grouped,
        [date]: [...(grouped[date] || []), call],
      };
    },
    {}
  );

  return Object.entries(groupedCalls);
};

export const getStatusName = (status: IncomingCallStatus) => {
  switch (status) {
    case 'ABANDON':
      return 'Missed';
    case 'SONNERIE':
      return 'Incoming';
    case 'RACCROCHE':
      return 'Ended';
    case 'DECROCHE':
      return 'Picked';
    default:
      return 'N/A';
  }
};

// NOTE: Get name on guest or from upcoming reservation/queue/waitlist if its a temporary guest reservation,
export const getGuestName = ({
  name,
  firstName,
  lastName,
}: {
  name?: string;
  firstName?: string;
  lastName?: string;
}) => {
  if (firstName || lastName) {
    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
  }

  if (name) {
    return name.trim();
  }

  return '';
};

// Ensure the date is displayed with today and yesterday
export function getUpcomingDateToFromNow(startDate: string, endDate?: string) {
  const start = moment(startDate, SERVER_DATE_TIME);
  const end = endDate ? moment(endDate, SERVER_DATE_TIME) : null;
  let format = "DD MMM'YY, LT";

  if (end && !start.isSame(end, 'minute')) {
    format = `${format} - [${end.format('LT')}]`;
  }

  return start.calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: `ddd ${format}`,
    lastDay: `[Yesterday] ${format}`,
    sameDay: `[Today] ${format}`,
    nextDay: `[Tomorrow] ${format}`,
    nextWeek: `ddd ${format}`,
    // when the date is further away
    sameElse: `ddd ${format}`,
  });
}

export function getIsInAppNotificationEnabled(venueId: VenueId) {
  return getDataFromStorage(INCOMING_CALL_NOTIFICATION_ENABLED(venueId)) === 'enabled';
}

export function setIsInAppNotificationEnabled(venueId: VenueId, state: 'enabled' | 'disabled') {
  setDataToStorage(INCOMING_CALL_NOTIFICATION_ENABLED(venueId), state);
}

export function setupIncomingCallInAppNotification(venueId: VenueId) {
  // Since the possible value for this key in localStorage is 'enabled' and 'disabled', it will be true if set
  if (getDataFromStorage(INCOMING_CALL_NOTIFICATION_ENABLED(venueId))) {
    return;
  }

  // If null or undefined from the above check, then by default we keep notifications enabled per venue
  setIsInAppNotificationEnabled(venueId, 'enabled');
}

export function whatsAppMissedCallMessage({
  name,
  widgetUrl,
}: {
  name: string;
  widgetUrl: string;
}) {
  return `Hi${name ? ` ${name}` : ''},\n\nUnfortunately we missed your call.\nYou can create reservations at ${widgetUrl} or edit your reservation via your confirmation message.\n\nIf you have other matters please do try reaching out to us again at a later time.`;
}

export const getSlotEndTime = ({
  slotStartTime,
  turnTime,
  format = SERVER_DATE_TIME,
}: {
  slotStartTime: string;
  turnTime: number;
  format?: string;
}) => moment(slotStartTime, SERVER_DATE_TIME).add(Math.abs(turnTime), 'minutes').format(format);

export const getCallDateTime = (startDate: string, endDate?: string) => {
  const start = moment(startDate, SERVER_DATE_TIME);
  const end = endDate ? moment(endDate, SERVER_DATE_TIME) : null;
  let format = "ddd DD MMM'YY, LT";

  if (end && !start.isSame(end, 'minute')) {
    format = `${format} - [${end.format('LT')}]`;
  }

  return start.format(format);
};
