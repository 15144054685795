import React, { createContext, useCallback, useMemo, useState } from 'react';
import { getDataFromStorage, isMobileDevice, setDataToStorage } from '@umai/common';
import { isTabletDevice } from 'utils/devices';
import type { AppCallPopup } from 'modules/IncomingCall/types';
import type { ReferralVisibility } from 'modules/Partner/types';

type AppContextType = {
  isVenueSettings: boolean;
  handleSettingsRouteChange: (path: string) => void;
  hasSideBar: () => boolean;
  callPopup: AppCallPopup;
  setCallPopup: (data: AppCallPopup) => void;
  referralVisibility: ReferralVisibility;
  setReferralVisibility: React.Dispatch<React.SetStateAction<ReferralVisibility>>;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isVenueSettings, setIsVenueSettings] = useState(false);
  const [callPopup, setCallPopup] = useState<AppCallPopup>(
    getDataFromStorage('umai-call-popup') || { isVisible: false, data: null }
  );
  const [referralVisibility, setReferralVisibility] = useState<ReferralVisibility>({
    isBannerVisible: false,
    isPopupVisible: false,
  });

  const setCallPopupData = useCallback(
    (data: AppCallPopup) => {
      setDataToStorage('umai-call-popup', data);
      setCallPopup(data);
    },
    [setCallPopup]
  );

  const handleSettingsRouteChange = (path: string) => {
    setIsVenueSettings(/venue-settings/i.test(path));
  };

  const hasSideBar = () => {
    // We do not show the side bar on mobile or venue settings on tablet only
    if (isMobileDevice || (isTabletDevice && isVenueSettings)) {
      return false;
    }
    return true;
  };

  const appState = useMemo(
    () => ({
      isVenueSettings,
      callPopup,
      handleSettingsRouteChange,
      hasSideBar,
      setCallPopup: setCallPopupData,
      referralVisibility,
      setReferralVisibility,
    }),
    [
      isVenueSettings,
      callPopup,
      handleSettingsRouteChange,
      hasSideBar,
      setCallPopupData,
      referralVisibility,
      setReferralVisibility,
    ]
  );

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
