import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { formatDateToServerDate } from 'utils/date-and-time';
import { getQueueItems } from 'modules/QueueList/actions';
import { selectCurrentVenueId, selectIsQueueSubscribed } from 'modules/Partner/selectors';
import { selectFooterDate } from 'modules/Footer/selectors';
import { getQueueCacheKey } from 'modules/QueueList/services';

const useFetchQueueList = () => {
  const dispatch = useAppDispatch();
  const isQueueSubscribed = useAppSelector(selectIsQueueSubscribed);
  const footerDate = useAppSelector(selectFooterDate);
  const venueId = useAppSelector(selectCurrentVenueId);

  useEffect(() => {
    let queueController: any = null;
    if (venueId && footerDate && isQueueSubscribed) {
      const date = formatDateToServerDate(footerDate);
      queueController = dispatch(
        getQueueItems({
          venueId,
          date,
          newCacheKey: getQueueCacheKey({ venueId, date: footerDate }),
          showLoader: true,
        })
      );
    }

    return () => {
      queueController?.abort();
    };
  }, [venueId, footerDate, isQueueSubscribed]);
};

export default useFetchQueueList;
