import React, { useContext } from 'react';
import ModalPopup from 'components/modals/ModalPopup';
import classNames from 'classnames';
import { useReferral } from 'modules/Partner/hooks/useReferral';
import styles from './Referral.module.scss';
import { AppContext } from 'contexts/app';

const ReferralPopup = () => {
  const { referralVisibility } = useContext(AppContext);
  const { handleClosePopup, handleReferral, rewardOffer } = useReferral();

  return (
    <ModalPopup modalIsOpen={referralVisibility.isPopupVisible} onHideClose={handleClosePopup}>
      <section className={styles.popupContainer}>
        <h3 className={styles.title}>Refer friends, get rewarded</h3>
        <img
          src={`${process.env.PUBLIC_URL}/restaurant-meeting.jpg`}
          alt="Restaurant meeting"
          className={styles.popupImage}
        />
        <h4 className={styles.reward}>Earn up to {rewardOffer} per referral</h4>
        <p className={styles.text}>
          The reward can go to you as an individual or to the restaurant, you decide.
          <br />
          Click below to find out more.
        </p>
        <div className={styles.buttonsWrapper}>
          <button
            type="button"
            onClick={handleReferral}
            className={classNames('uB-btn-success', styles.referralBtn)}
          >
            Learn more
          </button>
          <button
            type="button"
            onClick={handleClosePopup}
            className={classNames('uB-btn-outline-dark', styles.referralBtn, styles.noThanksBtn)}
          >
            No thank you
          </button>
        </div>
      </section>
    </ModalPopup>
  );
};

export default ReferralPopup;
