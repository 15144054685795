import React from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectCurrencyFormatter, selectCurrentVenueLanguage } from 'modules/Partner/selectors';
import { formatAmountWithCurrencySymbol } from '@umai/common';

interface CurrencyFormatterProps {
  amount?: string | number | null;
  minDigits?: number;
}

const CurrencyFormatter = ({ amount = 0, minDigits = 2 }: CurrencyFormatterProps) => {
  const symbol = useAppSelector(selectCurrencyFormatter);
  const defaultLanguage = useAppSelector(selectCurrentVenueLanguage);

  return (
    <>
      {formatAmountWithCurrencySymbol({
        amount: Number(amount) || 0,
        symbol,
        defaultLanguage: defaultLanguage.language,
        minDigits,
      })}
    </>
  );
};

export default CurrencyFormatter;
