import { useQuery } from '@tanstack/react-query';
import Partner from '../apis';
import { selectVenueCountryIsoCode } from 'modules/Partner/selectors';
import { useAppSelector } from 'hooks/redux';

export default function useFetchReferralRewards() {
  const venueCountryIsoCode = useAppSelector(selectVenueCountryIsoCode);

  const { isLoading, data: referralReward } = useQuery({
    queryKey: [venueCountryIsoCode],
    queryFn: () => Partner.fetchReferralRewards(venueCountryIsoCode),
    enabled: !!venueCountryIsoCode,
    select: (data) => data.rewards?.[0],
  });

  return { referralReward, isLoading };
}
