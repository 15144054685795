import React from 'react';
import { limitString } from '@umai/common';
import NotesIcon from 'components/icons/NotesIcon';
import styles from './Content.module.scss';

function Notes({ note }: { note?: string | null }) {
  if (!note?.length) {
    return null;
  }

  return (
    <span>
      <NotesIcon />
      <p className={styles.note}>{limitString(note, 1000)}</p>
    </span>
  );
}

export default Notes;
