import React, { useContext } from 'react';
import classnames from 'classnames';
import { AppContext } from 'contexts/app';
import { formatServerDate } from 'utils/date-and-time';
import { getStatusName } from 'modules/IncomingCall/services';
import type { IncomingCall } from 'modules/IncomingCall/types';
import IncomingCallIcon from 'components/icons/IncomingCallIcon';
import MinimizeIcon from 'components/icons/MinimizeIcon';
import CloseIcon from 'components/icons/CloseIcon';
import IsToday from './IsToday';
import styles from './Header.module.scss';

type Props = {
  data: IncomingCall;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Header({ data, setOpen }: Props) {
  const { setCallPopup } = useContext(AppContext);
  const isMissed = data.callCurrentStatus === 'ABANDON';

  const handleMinimize = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setCallPopup({ isVisible: false, data: null });
  };

  return (
    <header className={classnames(styles.header, { [styles.isMissed]: isMissed })}>
      <div className={styles.title} aria-label="call header actions">
        <IncomingCallIcon width={18.33} height={18.33} />
        <h4 className={styles.text}>
          {getStatusName(data.callCurrentStatus)} call
          {data.callDateMillis ? (
            <span className={styles.time}>{formatServerDate(data.callDateMillis, 'LT')}</span>
          ) : null}
        </h4>
        <IsToday
          slotStartTime={data.upcomingReservation?.slotStartTime}
          startTimeWithDate={data.waitlist?.startTimeWithDate}
          isMissed={isMissed}
        />
      </div>
      <div className={styles.actions} aria-label="call header actions">
        <button
          type="button"
          className={styles.action}
          onClick={handleMinimize}
          aria-label="Minimize incoming call"
        >
          <MinimizeIcon />
        </button>
        <button
          type="button"
          className={styles.action}
          onClick={handleClose}
          aria-label="Close incoming call"
        >
          <CloseIcon width={14} height={15} />
        </button>
      </div>
    </header>
  );
}

export default Header;
