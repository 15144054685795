import { createSelector } from 'reselect';
import type { RootState } from 'redux-store';
import { ROUTES, NO_PERMISSION_REQUIRE_ROUTES } from 'constants/routes';
import {
  GUEST_LIST_OPERATIONAL_PERMISSIONS,
  OPERATIONAL_PERMISSIONS,
} from 'constants/operational-permission';
import { isMobileDevice } from 'utils/devices';
import { checkAuthorization } from './services';

const selectCurrentUser = ({ auth }: RootState) => auth.currentUser;

export const selectAccessToken = ({ auth }: RootState) => auth.accessToken;

export const selectIsAuthenticated = createSelector(
  selectAccessToken,
  (accessToken) => !!accessToken
);

export const selectIsPasswordResetSuccess = ({ auth }: RootState) => auth.isPasswordResetSuccess;

export const selectIsValidPasswordToken = ({ auth }: RootState) => auth.isValidPasswordToken;

export const selectIsAuthSubmitting = ({ auth }: RootState) => auth.isAuthSubmitting;

export const selectIsLoadingCurrentUser = ({ auth }: RootState) => auth.isLoadingCurrentUser;

export const selectCurrentUserId = ({ auth }: RootState) => auth.currentUser?.id;

export const selectCurrentUserEmail = ({ auth }: RootState) => auth.currentUser?.email;

export const selectHomeRoute = createSelector(selectCurrentUser, (currentUser) => {
  if (currentUser) {
    const { permissions, isOwner } = currentUser;
    if (permissions.includes(OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS) || isOwner) {
      return ROUTES.RESERVATIONS;
    }
    if (permissions.includes(OPERATIONAL_PERMISSIONS.VIEW_FEEDBACK)) {
      return ROUTES.FEEDBACK_ANALYTICS;
    }

    // The below route are not shown on mobile view

    if (permissions.includes(OPERATIONAL_PERMISSIONS.VIEW_ANALYTICS) && !isMobileDevice) {
      return ROUTES.TRAFFIC_ANALYTICS;
    }
    // The below route are not shown on mobile view
    if (
      permissions.includes(OPERATIONAL_PERMISSIONS.TAKEAWAY_AND_DELIVERY_OPERATIONS) &&
      !isMobileDevice
    ) {
      return ROUTES.SHOP_DASHBOARD;
    }
    if (permissions.includes(OPERATIONAL_PERMISSIONS.QUEUE_OPERATIONS)) {
      return ROUTES.QUEUE_LIST;
    }
    if (GUEST_LIST_OPERATIONAL_PERMISSIONS.some((item) => permissions.includes(item))) {
      return ROUTES.GUEST_LIST;
    }
  }
  return ROUTES.LOGOUT;
});

export const selectIsRouteAccessAuthorized = (allowedPermission: any, path: any) =>
  createSelector(selectCurrentUser, (currentUser) => {
    if (!currentUser) {
      return false;
    }
    if (currentUser.isOwner) {
      return true;
    }

    const isAuthorizationNeeded = !NO_PERMISSION_REQUIRE_ROUTES.includes(path);

    if (isAuthorizationNeeded) {
      return checkAuthorization(allowedPermission, currentUser);
    }
    return true;
  });

export const selectIsElementAccessAuthorized = (allowedPermission: any) =>
  createSelector(selectCurrentUser, (currentUser) => {
    if (!currentUser) {
      return false;
    }
    if (currentUser.isOwner) {
      return true;
    }
    return checkAuthorization(allowedPermission, currentUser);
  });
