import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DATE_FORMAT, showErrorMessage, useViewPortContext } from '@umai/common';
import { type Routes, ROUTES } from 'constants/routes';
import { getCurrentDateAndTime } from 'utils/date-and-time';
import { useViewReservationDetails } from 'modules/ReservationsList/hooks/useViewReservationDetails';
import { useViewWaitlistDetails } from 'modules/Waitlist/hooks/useViewWaitlistDetails';
import { useViewQueueDetails } from 'modules/QueueList/hooks/useViewQueueDetails';
import { selectVenueCommunicationEdit } from 'modules/Partner/selectors';
import {
  editReservation,
  resetReservationFunnel,
  setIsReservationWithGuest,
  setReservationFunnelWaitlist,
  setWalkinFunnelQueueList,
} from 'modules/ReservationFunnel/actions';
import { RESERVATION_FUNNEL_STEPS } from 'modules/ReservationFunnel/constants';
import useFetchReservation from 'modules/IncomingCall/hooks/useFetchReservation';
import useFetchGuest from './useFetchGuest';
import useFetchQueue from './useFetchQueue';
import useFetchWaitlist from './useFetchWaitlist';

type IncomingCallAction = {
  waitlistId?: number | undefined;
  startTimeWithDate?: string | undefined;
  queueId?: number | undefined;
  dateTimeStart?: string | undefined;
  reservationId?: number | undefined;
  slotStartTime?: string | undefined;
  reservationStatus?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  guestId?: number | undefined;
  phone?: string;
  from?: Routes;
  callback?: () => void;
};

export function useIncomingCallActions({ callback, ...data }: IncomingCallAction) {
  const { mobile } = useViewPortContext();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const editCommunicationSettings = useAppSelector(selectVenueCommunicationEdit);
  const viewReservationDetails = useViewReservationDetails();
  const viewWaitlistDetails = useViewWaitlistDetails();
  const viewQueueDetails = useViewQueueDetails();
  const { isFetchingGuest, refetchGuest } = useFetchGuest({
    guestId: data.guestId,
    enabled: false,
  });
  const { isFetchingReservation, refetchReservation } = useFetchReservation({
    reservationId: data.reservationId,
    enabled: false,
  });
  const { isFetchingQueue, refetchQueue } = useFetchQueue({
    queueId: data.queueId,
    enabled: false,
  });
  const { isFetchingWaitlist, refetchWaitlist } = useFetchWaitlist({
    waitlistId: data.waitlistId,
    enabled: false,
  });

  const resetFunnel = () => {
    // Reset the reservation funnel, if opened  IncomingCall popup opened on it. to avoid state conflicts when reopening funnel
    if (location.pathname === ROUTES.RESERVATION_FUNNEL) {
      dispatch(resetReservationFunnel());
    }
  };

  // ----------------------------- Guest -----------------------------

  const handleViewGuest = () => {
    if (data.guestId) {
      resetFunnel();

      if (callback) {
        callback();
      }

      history.push(
        { pathname: '/guests', search: `?guestId=${data.guestId}` },
        data.from ? { from: data.from } : undefined
      );
    }
  };

  const handleCreateGuest = () => {
    resetFunnel();

    if (callback) {
      callback();
    }

    history.push(
      { pathname: '/guests', search: `?add-guest=${data.phone}` },
      data.from ? { from: data.from } : undefined
    );
  };

  // ----------------------------- Reservation -----------------------------

  const handleViewReservation = () => {
    if (data.reservationId && data.slotStartTime && data.reservationStatus) {
      resetFunnel();

      viewReservationDetails({
        dateTime: data.slotStartTime,
        id: data.reservationId,
        status: data.reservationStatus,
      });

      if (callback) {
        callback();
      }
    }
  };

  const handleAddReservation = async () => {
    const { data: primaryGuest } = await refetchGuest();
    dispatch(resetReservationFunnel());
    // NOTE: Using editReservation with isEdit false here because,
    //       it provides a complete setup for opening funnel with the required data to pre populate like pax, guest, fill input in guest step etc...
    //       Would be nice in future we separate out this function for adding a reservation.
    dispatch(
      editReservation({
        reservation: {
          walkin: false,
          ...(data.guestId && primaryGuest && { primaryGuest }),
          ...(!data.guestId && {
            phone: data.phone,
            firstName: data.firstName,
            lastName: data.lastName,
          }),
          guestCount: mobile ? null : 2,
        },
        funnelDate: getCurrentDateAndTime().format(DATE_FORMAT),
        step: RESERVATION_FUNNEL_STEPS.FIRST_STEP,
        isTimeEdit: false,
        isEdit: false,
        isCustomTime: false,
        hasDateUpdated: true,
      })
    );

    if (data.guestId) {
      dispatch(setIsReservationWithGuest());
    }

    if (callback) {
      callback();
    }

    history.push(ROUTES.RESERVATION_FUNNEL, data.from ? { from: data.from } : undefined);
  };

  const handleEditReservation = async () => {
    try {
      const { data: reservation } = await refetchReservation({ throwOnError: true });
      if (reservation) {
        dispatch(resetReservationFunnel());
        dispatch(
          editReservation({
            reservation,
            step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
            isTimeEdit: false,
            communicationSettings: editCommunicationSettings,
          })
        );

        if (callback) {
          callback();
        }

        history.push(ROUTES.RESERVATION_FUNNEL, data.from ? { from: data.from } : undefined);
      }
    } catch (error) {
      showErrorMessage('Un expected error fetching the upcoming reservation');
    }
  };

  // ----------------------------- Waitlist -----------------------------

  const handleViewWaitlist = () => {
    if (data.waitlistId && data.startTimeWithDate) {
      resetFunnel();

      viewWaitlistDetails({
        dateTime: data.startTimeWithDate,
        id: data.waitlistId,
      });

      if (callback) {
        callback();
      }
    }
  };

  const handleAddReservationFromWaitlist = async () => {
    try {
      const { data: waitlistItem } = await refetchWaitlist({ throwOnError: true });
      if (waitlistItem) {
        dispatch(resetReservationFunnel());
        dispatch(setReservationFunnelWaitlist(waitlistItem, mobile));

        if (callback) {
          callback();
        }

        history.push(ROUTES.RESERVATION_FUNNEL, data.from ? { from: data.from } : undefined);
      }
    } catch (error) {
      showErrorMessage('Un expected error fetching the upcoming waitlist');
    }
  };

  // ----------------------------- Queue -----------------------------

  const handleViewQueue = () => {
    if (data.queueId && data.dateTimeStart) {
      resetFunnel();

      viewQueueDetails({
        dateTime: data.dateTimeStart,
        id: data.queueId,
      });

      if (callback) {
        callback();
      }
    }
  };

  const handleSeatReservationFromQueue = async () => {
    try {
      const { data: queueItem } = await refetchQueue({ throwOnError: true });
      if (queueItem) {
        dispatch(resetReservationFunnel());
        dispatch(setWalkinFunnelQueueList(queueItem, mobile));

        if (callback) {
          callback();
        }

        history.push(ROUTES.WALKIN_FUNNEL, data.from ? { from: data.from } : undefined);
      }
    } catch (error) {
      showErrorMessage('Un expected error fetching the upcoming queue');
    }
  };

  return {
    isFetchingGuest,
    isFetchingReservation,
    isFetchingQueue,
    isFetchingWaitlist,
    handleViewGuest,
    handleCreateGuest,
    handleViewReservation,
    handleAddReservation,
    handleEditReservation,
    handleViewWaitlist,
    handleAddReservationFromWaitlist,
    handleViewQueue,
    handleSeatReservationFromQueue,
  };
}
