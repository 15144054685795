import React from 'react';
import classnames from 'classnames';
import { useAppSelector } from 'hooks/redux';
import { combineArrays, combineTagArrays } from 'utils/array-helpers';
import { selectCurrentVenueWidgetUrl } from 'modules/Partner/selectors';
import { getGuestNotes } from 'modules/ReservationsList/services';
import { getGuestName, whatsAppMissedCallMessage } from 'modules/IncomingCall/services';
import type { IncomingCall } from 'modules/IncomingCall/types';
import CopyText from 'components/shared/CopyText';
import WhatsAppButton from 'components/ui/WhatsAppButton';
import TransactionsSummary from 'components/shared/GuestTransactionsSummary/TransactionsSummary';
import ReservationPayment from 'components/shared/Payments/Components/ReservationPayment';
import SupplementsPanel from 'components/panels/SupplementsPanel';
import Tags from 'components/shared/Tags';
import Notes from './Notes';
import Name from './Name';
import DetailsHeader from './DetailsHeader';
import Details from './Details';
import styles from './Content.module.scss';

type Props = {
  data: IncomingCall;
};

function Content({ data }: Props) {
  const widgetUrl = useAppSelector(selectCurrentVenueWidgetUrl);
  const upcomingCall = data.waitlist || data.queue || data.upcomingReservation;
  const guestName = getGuestName({
    name: data.guest?.name,
    firstName: upcomingCall?.firstName,
    lastName: upcomingCall?.lastName,
  });
  // combine guest tags so that it only shows the unique tags
  const combinedGuestTags = combineTagArrays({
    autoTags: data.guest?.automatedTags,
    tags: data.guest?.tags,
  });
  // combine tags so that it only shows the unique tags,
  // NOTE: automatedTags are only available in upcomingReservation
  const combinedRsvTags = combineTagArrays({
    autoTags: data.upcomingReservation?.automatedTags ?? [],
    tags: combineArrays(upcomingCall?.tags, upcomingCall?.reservationTags),
  });

  return (
    <section className={styles.content} aria-label="incoming call details">
      <div className={styles.name}>
        <Name guest={data.guest} upcomingCall={upcomingCall} />
        <span>
          <a
            href={`tel:${data.callPhoneNumber}`}
            // NOTE: if name not exists on guest or upcoming reservation/queue/waitlist then it becomes a heading
            className={classnames(styles.phone, { [styles.heading]: !guestName.length })}
          >
            <CopyText text={data.callPhoneNumber}>
              {!guestName.length ? data.callPhoneNumber : <>( {data.callPhoneNumber} )</>}
            </CopyText>
          </a>
          <WhatsAppButton
            phone={data.callPhoneNumber}
            size={16}
            text={whatsAppMissedCallMessage({ name: guestName, widgetUrl })}
          />
        </span>
      </div>
      {data.guest ? (
        <div className={styles.guest} aria-label="guest details">
          {combinedGuestTags.length ? <Tags tags={combinedGuestTags} className="guest" /> : null}
          <Notes note={getGuestNotes(data.guest.guestNote)} />
          <TransactionsSummary
            className={styles.summary}
            customReservationSummary={{
              venue: {
                totalCancelledReservations: data.guest.totalCancelledReservations || 0,
                totalNoShowedReservations: data.guest.totalNoShowedReservations || 0,
                totalVisit: data.guest.totalVisit || 0,
                avgVisitReservations: `${data.guest.avgVisitReservations || 0}`,
                avgCoverReservations: `${data.guest.avgCoverReservations || 0}`,
                netSpend: `${data.guest.netSpend || 0}`,
              },
            }}
            isTooltipEnabled={false}
            type="venue"
            header={null}
            order={{
              visits: 1,
              cancellations: 2,
              noShows: 3,
              totalSpend: 4,
              avgPerVisit: 5,
              avgPerCover: 6,
            }}
          />
        </div>
      ) : null}
      {upcomingCall ? (
        <div className={styles.upcomingReservation} aria-label="upcoming details">
          <DetailsHeader data={data} />
          {combinedRsvTags.length ? <Tags tags={combinedRsvTags} className="reservation" /> : null}
          <Details
            guestCount={upcomingCall.guestCount}
            startTimeWithDate={data.waitlist?.startTimeWithDate}
            endTimeWithDate={data.waitlist?.endTimeWithDate}
            dateTimeStart={data.queue?.dateTimeStart}
            slotStartTime={data.upcomingReservation?.slotStartTime}
            turnTime={data.upcomingReservation?.turnTime}
            tables={data.upcomingReservation?.tables}
          />
          {data.upcomingReservation ? (
            <ReservationPayment
              className={styles.payment}
              hasDepositPayableFee={data.upcomingReservation.hasDepositPayableFee}
              payableFee={data.upcomingReservation.depositPayableFee}
              paymentSummary={data.upcomingReservation.paymentSummary}
              status={data.upcomingReservation.status}
              hasSupplements={!!data.upcomingReservation.supplements?.length}
            />
          ) : null}
          <Notes note={upcomingCall.notes} />
          {data.upcomingReservation?.supplements?.length ? (
            <section className={styles.supplements}>
              <SupplementsPanel
                supplements={data.upcomingReservation.supplements}
                paymentInfo={data.upcomingReservation.paymentInfo}
                depositPayableFee={data.upcomingReservation.depositPayableFee}
                showFeeDetails={false}
              />
            </section>
          ) : null}
        </div>
      ) : null}
      {data.lastReservation ? (
        <div className={styles.lastReservation} aria-label="last reservation details">
          <h6 className={styles.heading}>
            <DetailsHeader data={data} />
          </h6>
          <Details
            slotStartTime={data.lastReservation.slotStartTime}
            turnTime={data.lastReservation.turnTime}
            guestCount={data.lastReservation.guestCount}
            tables={data.lastReservation.tables}
          />
          <ReservationPayment
            className={styles.payment}
            hasDepositPayableFee={data.lastReservation.hasDepositPayableFee}
            payableFee={data.lastReservation.depositPayableFee}
            paymentSummary={data.lastReservation.paymentSummary}
            status={data.lastReservation.status}
            hasSupplements={!!data.lastReservation.supplements?.length}
          />
          {data.lastReservation.supplements?.length ? (
            <section className={styles.supplements}>
              <SupplementsPanel
                supplements={data.lastReservation.supplements}
                paymentInfo={data.lastReservation.paymentInfo}
                depositPayableFee={data.lastReservation.depositPayableFee}
                showFeeDetails={false}
              />
            </section>
          ) : null}
        </div>
      ) : null}
    </section>
  );
}

export default Content;
