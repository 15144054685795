import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { getReservationListType } from 'modules/ReservationsList/services';
import { updateReservationListType } from 'modules/ReservationsList/actions';

const useReservationListType = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const reservationListType = getReservationListType(location.pathname);
    if (reservationListType) {
      dispatch(updateReservationListType(reservationListType));
    }
  }, [dispatch, location.pathname]);
};

export default useReservationListType;
