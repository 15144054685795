import React from 'react';
import PropTypes from 'prop-types';
import { type SwipeCallback, useSwipeable } from 'react-swipeable';

interface SwipeableWrapperProps {
  delta?: number;
  preventScrollOnSwipe?: boolean;
  trackTouch?: boolean;
  trackMouse?: boolean;
  rotationAngle?: number;
  onSwipedLeft?: SwipeCallback;
  onSwipedRight?: SwipeCallback;
  onSwipedUp?: SwipeCallback;
  onSwipedDown?: SwipeCallback;
  className?: string;
  children?: React.ReactNode;
}

const SwipeableWrapper = ({
  delta = 160,
  preventScrollOnSwipe = false,
  trackTouch = true,
  trackMouse = false,
  rotationAngle = 0,
  onSwipedLeft = () => {},
  onSwipedRight = () => {},
  onSwipedUp = () => {},
  onSwipedDown = () => {},
  className = '',
  children,
  ...restOfProps
}: SwipeableWrapperProps) => {
  const handlers = useSwipeable({
    delta,
    preventScrollOnSwipe,
    trackTouch,
    trackMouse,
    rotationAngle,
    onSwipedLeft,
    onSwipedRight,
    onSwipedUp,
    onSwipedDown,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={className} {...handlers} {...restOfProps}>
      {children}
    </div>
  );
};

SwipeableWrapper.propTypes = {
  delta: PropTypes.number,
  preventScrollOnSwipe: PropTypes.bool,
  trackTouch: PropTypes.bool,
  trackMouse: PropTypes.bool,
  rotationAngle: PropTypes.number,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  onSwipedUp: PropTypes.func,
  onSwipedDown: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SwipeableWrapper;
