import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';
import type { Reservation } from 'modules/ReservationsList/types';
import { ChargingFee, CommunicationSettings } from './types';

class ReservationFunnel extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  getSlots({ date, partySize, venueId }: any, config: any) {
    return this.fetch('/slots', { date, partySize, venueId }, config);
  }

  getFunnelTables({ venueId }: any) {
    return this.fetch(`/tables`, { venueId });
  }

  getAssignableTables({ venueId, guestCount, startDateTime, endDateTime, reservationId }: any) {
    return this.fetch(`/tables/assignable_tables2`, {
      venueId,
      reservationId,
      guestCount,
      startDateTime,
      endDateTime,
      roomIds: [],
    });
  }

  createReservation(data: any, params: any, idempotencyKey: any) {
    return this.create<{ reservation: Reservation }>(`/reservations`, data, {
      params,
      headers: {
        'Idempotency-Key': idempotencyKey,
      },
    });
  }

  updateReservation(data: any, params: any, id: any) {
    return this.update<{ reservation: Reservation }>(`/reservations/${id}`, data, { params });
  }

  getTablesCombination({ venueId }: any) {
    return this.fetch('/table_combinations/index_v2', { venueId });
  }

  getChargingFees({
    venueId,
    guestCount,
    startDateTime,
  }: {
    venueId: number;
    guestCount: number;
    startDateTime: string;
  }) {
    return this.fetch<{ chargingFee: ChargingFee }>('/charging_fees/check', {
      venueId,
      guestCount,
      startDateTime,
    });
  }

  getPaymentCommunication({ venueId }: { venueId: number }) {
    return this.fetch<CommunicationSettings>(
      `/venues/${venueId}/communication_settings/fetch_by_trigger_name?trigger_name=payment`
    );
  }
}

export default new ReservationFunnel();
