import type { RootState } from 'redux-store';
import {
  RESERVATION_LIST_TYPES,
  CURRENT_LIST_KEY,
  FINISHED_LIST_KEY,
  STATUS_LOADING_KEY,
  STATUSES,
} from 'modules/ReservationsList/constants';
import { createSelector } from 'reselect';
import {
  applyFilters,
  getGuestCount,
  getReservationCount,
} from 'modules/ReservationsList/services';
import { sortSlotsByDateTime } from 'modules/ReservationsList/slice';
import { selectReservationFilters } from 'modules/Filter/selectors';
import type { Reservation, ReservationListTypes } from 'modules/ReservationsList/types';

export const selectAllReservation = ({ reservation }: RootState) => reservation;

export const selectReservationLoadStatusByType = createSelector(
  selectAllReservation,
  (reservation) => {
    if (reservation.reservationListType === RESERVATION_LIST_TYPES.RESERVED)
      return reservation.currentListLoading;
    return reservation.finishedListLoading;
  }
);

export const selectReservationSlotsByListType = createSelector(
  selectAllReservation,
  (reservation) => {
    const key =
      reservation.reservationListType === RESERVATION_LIST_TYPES.FINISHED
        ? FINISHED_LIST_KEY
        : CURRENT_LIST_KEY;
    return Object.values(reservation[key]?.slots || {})
      .sort(sortSlotsByDateTime)
      .map((slot) => ({
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        ...slot,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        reservations: slot.reservations
          .sort()
          .map((rId: any) => reservation[key]?.reservations?.[rId] || {}),
      }));
  }
);

export const selectSearchText = ({ reservation }: RootState) => reservation.searchText;

export const selectFilteredReservationSlots = createSelector(
  [
    (_, availabilityId) => availabilityId,
    selectSearchText,
    selectReservationSlotsByListType,
    selectReservationFilters,
  ],
  (availabilityId, searchText, slots, reservationFilters) =>
    applyFilters({ slots, availabilityId, searchText, reservationFilters })
);

export const selectReservationById = createSelector(
  [
    (_: RootState, reservationId: number, reservationListType?: ReservationListTypes) => ({
      reservationId,
      reservationListType,
    }),
    selectAllReservation,
  ],
  ({ reservationId, reservationListType }, reservation) => {
    if (reservationListType) {
      const key =
        reservationListType === RESERVATION_LIST_TYPES.RESERVED
          ? CURRENT_LIST_KEY
          : FINISHED_LIST_KEY;
      return reservation[key]?.reservations?.[reservationId] || {};
    }

    return (
      reservation[CURRENT_LIST_KEY]?.reservations?.[reservationId] ||
      reservation[FINISHED_LIST_KEY]?.reservations?.[reservationId] ||
      {}
    );
  }
);

export const selectReservationListType = ({ reservation }: RootState) =>
  reservation.reservationListType;

export const selectViewReservationId = ({ reservation }: RootState) =>
  reservation.selectedReservationId;

export const selectReservationSuggestionId = (reservationId: any) =>
  createSelector(
    (state: RootState) => selectReservationById(state, reservationId),
    (reservation) => reservation?.suggestionId
  );

export const selectReservationCountByType = createSelector(
  [(state, availabilityId) => ({ state, availabilityId })],
  ({ state, availabilityId }) => {
    const slots = selectFilteredReservationSlots(state, availabilityId);
    return {
      guestCount: getGuestCount(slots),
      reservationCount: getReservationCount(slots),
    };
  }
);

export const selectReservationStatusError = ({ reservation }: RootState) => reservation.statusError;

export const selectReservationStatusLoading =
  (reservationId: any) =>
  ({ reservation }: RootState) =>
    reservation?.[STATUS_LOADING_KEY(reservationId)] || false;

export const selectCurrentReservationList = createSelector(
  ({ reservation }: RootState): Reservation[] => reservation[CURRENT_LIST_KEY]?.reservations,
  (reservations) => Object.values(reservations || {})
);

export const selectFinishStatusReservationList = createSelector(
  ({ reservation }: RootState): Reservation[] => reservation[FINISHED_LIST_KEY]?.reservations,
  (reservations) =>
    Object.values(reservations || {}).filter(
      ({ status }) => status.toUpperCase() === STATUSES.FINISH
    )
);

export const selectCurrentReservationLoadStatus = ({ reservation }: RootState) =>
  reservation.currentListLoading;

export const selectFinishReservationLoadStatus = ({ reservation }: RootState) =>
  reservation.finishedListLoading;

export const selectCurrentSlots = ({ reservation }: RootState) =>
  reservation[CURRENT_LIST_KEY].slots;

export const selectExportReservations = createSelector(
  [selectAllReservation],
  ({ isExportingReservations, abortController }) => ({
    isExportingReservations,
    abortController,
  })
);
