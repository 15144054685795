import React, { useContext } from 'react';
import { AppContext } from 'contexts/app';
import type { IncomingCall } from 'modules/IncomingCall/types';
import WaitlistFooter from './Waitlist';
import QueueFooter from './Queue';
import ReservationFooter from './Reservation';
import styles from './Footer.module.scss';

export default function Footer({ data }: { data: IncomingCall }) {
  const { setCallPopup } = useContext(AppContext);

  const callback = () => {
    setCallPopup({ isVisible: false, data: null });
  };

  const renderFooter = () => {
    if (data.waitlist) {
      return <WaitlistFooter waitlist={data.waitlist} callback={callback} />;
    }

    if (data.queue) {
      return <QueueFooter queue={data.queue} callback={callback} />;
    }

    // By default we handle all the cases in ReservationFooter for:
    // 1. guest, no guest or
    // 2.reservation, no reservation.
    return (
      <ReservationFooter
        upcomingReservation={data.upcomingReservation}
        guestId={data.guest?.id}
        callPhoneNumber={data.callPhoneNumber}
        callback={callback}
      />
    );
  };

  return <footer className={styles.footer}>{renderFooter()}</footer>;
}
