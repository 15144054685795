import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getReservationAvailabilities } from 'modules/Footer/actions';

// one place which is used to fetch and update all the dependent data and view's on FOOTER DATE change
// view related to reservation list and waitlist are updated in there respective hooks
const useFooterDateChange = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let availabilitiesController: any = null;

    try {
      if (venueId && footerDate) {
        availabilitiesController = dispatch(
          getReservationAvailabilities({ venueId, footerDate, showLoader: true })
        );
      }
    } catch (error) {
      console.error('Error in change footer date from hook [useFooterDateChange]:', error);
    }

    return () => {
      availabilitiesController?.abort();
    };
  }, [footerDate]);
};

export default useFooterDateChange;
