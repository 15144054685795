import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import {
  changeAvailabilities,
  getCalendarData,
  getReservationAvailabilities,
  resetCalendarData,
} from 'modules/Footer/actions';
import { getRoomTables, getVenueRooms } from 'modules/Room/actions';
import { ALL_DAY_AVAILABILITY, ALL_DAY } from 'modules/ReservationsList/constants';

// one place which is used to fetch and update all the dependent data and view's on VENUE change
// view related to reservation list and waitlist are updated in there respective hooks
const useVenueChange = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let roomsController: any = null;
    let tablesController: any = null;
    let availabilitiesController: any = null;

    try {
      if (venueId) {
        dispatch(changeAvailabilities(ALL_DAY_AVAILABILITY, ALL_DAY));
        roomsController = dispatch(getVenueRooms({ venueId, showLoader: true }));
        tablesController = dispatch(getRoomTables({ venueId, showLoader: true }));
        dispatch(resetCalendarData());
        dispatch(getCalendarData(footerDate));
        availabilitiesController = dispatch(
          getReservationAvailabilities({ venueId, footerDate, showLoader: true })
        );
      }
    } catch (error) {
      console.error('Error in change venue from hook [useVenueChange]:', error);
    }

    return () => {
      roomsController?.abort();
      tablesController?.abort();
      availabilitiesController?.abort();
    };
  }, [venueId]);
};

export default useVenueChange;
