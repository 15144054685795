import { useContext, useEffect } from 'react';
import {
  getDataFromStorage,
  isMobileDevice,
  setDataToStorage,
  getReferralRewardAndLink,
} from '@umai/common';
import { useAppSelector } from 'hooks/redux';
import { AppContext } from 'contexts/app';
import { selectCurrentUserEmail, selectIsAuthenticated } from 'modules/Auth/selectors';
import {
  selectCurrentVenueId,
  selectCurrentVenueLanguage,
  selectIsGuestCenterSubscribed,
  selectIsVenueAccessBlocked,
  selectVenueCountryIsoCode,
} from '../selectors';
import useFetchReferralRewards from './useFetchReferralRewards';

export const useReferral = () => {
  const { setReferralVisibility } = useContext(AppContext);
  const email = useAppSelector(selectCurrentUserEmail);
  const venueId = useAppSelector(selectCurrentVenueId);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isGuestCenterEnabled = useAppSelector(selectIsGuestCenterSubscribed);
  const isVenueAccessBlocked = useAppSelector(selectIsVenueAccessBlocked);
  const countryIsoCode = useAppSelector(selectVenueCountryIsoCode);
  const { referralReward } = useFetchReferralRewards();
  const defaultLanguage = useAppSelector(selectCurrentVenueLanguage);

  const { rewardOffer, openReferralLink } = getReferralRewardAndLink({
    venueId,
    email,
    amount: referralReward?.amount || '',
    currency: referralReward?.currency || '',
    countryIsoCode,
    defaultLanguage: defaultLanguage.language,
  });

  const REFERRAL_BANNER_LAST_OPENED = 'referralBannerLastOpened';
  const REFERRAL_POPUP_LAST_OPENED = 'referralPopupLastOpened';

  const handleCloseBanner = () => {
    setDataToStorage(REFERRAL_BANNER_LAST_OPENED, String(Date.now()));
    setReferralVisibility((prev) => ({
      ...prev,
      isBannerVisible: false,
    }));
  };

  const handleClosePopup = () => {
    setDataToStorage(REFERRAL_POPUP_LAST_OPENED, String(Date.now()));
    setReferralVisibility((prev) => ({ ...prev, isPopupVisible: false }));
  };

  const handleReferral = () => {
    openReferralLink();
    handleCloseBanner();
    handleClosePopup();
  };

  useEffect(() => {
    const ONE_MONTH_MS = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    const TWO_MONTHS_MS = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds
    const THREE_MONTHS_MS = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

    const referralBannerDisplayInterval = isMobileDevice ? TWO_MONTHS_MS : ONE_MONTH_MS;
    const referralBannerLastOpened = getDataFromStorage(REFERRAL_BANNER_LAST_OPENED);
    const referralPopupLastOpened = getDataFromStorage(REFERRAL_POPUP_LAST_OPENED);
    const currentTime = Date.now();

    if (isAuthenticated && isGuestCenterEnabled && rewardOffer && !isVenueAccessBlocked) {
      // Show popup if it's been more than 3 months since last opened
      if (currentTime - Number(referralPopupLastOpened) > THREE_MONTHS_MS) {
        setReferralVisibility((prev) => ({ ...prev, isPopupVisible: true }));
      }

      // Show banner if it's been more than the designated duration (1 month for non-mobile, 2 months for mobile)
      if (currentTime - Number(referralBannerLastOpened) > referralBannerDisplayInterval) {
        setReferralVisibility((prev) => ({ ...prev, isBannerVisible: true }));
      }
    }
  }, [
    setReferralVisibility,
    isAuthenticated,
    isGuestCenterEnabled,
    rewardOffer,
    isVenueAccessBlocked,
  ]);

  return {
    handleCloseBanner,
    handleReferral,
    handleClosePopup,
    openReferralLink,
    rewardOffer,
  };
};
