import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface SeatIconProps {
  width?: number;
  height?: number;
  color?: string;
}

function SeatIcon({ width = 20, height, color = COLORS.WHITE }: SeatIconProps) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M14.272 20a.734.734 0 01-.728-.739v-5.5H7.913v5.5a.728.728 0 11-1.456 0v-8.589L5.006.83a.738.738 0 01.631-.824.726.726 0 01.813.641l1.377 9.241h4.685A2.508 2.508 0 0115 12.41v6.852a.734.734 0 01-.728.738zm-6.359-8.636v.922h5.624a1.036 1.036 0 00-1.024-.922h-4.6z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

SeatIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default SeatIcon;
