import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import waitlistApis from 'modules/Waitlist/apis';
import { incomingCallKeys } from '../services';

export default function useFetchWaitlist({
  waitlistId,
  enabled = true,
}: {
  waitlistId?: number;
  enabled?: boolean;
}) {
  const venueId = useAppSelector(selectCurrentVenueId);

  const {
    isFetching: isFetchingWaitlist,
    data: waitlist,
    refetch: refetchWaitlist,
  } = useQuery({
    queryKey: incomingCallKeys.fetchWaitlist({ venueId, waitlistId }),
    queryFn: () => {
      if (waitlistId) {
        return waitlistApis.getWaitlist(waitlistId, venueId);
      }

      return null;
    },
    select: (data) => data?.notifylist,
    enabled: !!venueId && !!waitlistId && enabled,
  });

  return { waitlist, isFetchingWaitlist, refetchWaitlist };
}
