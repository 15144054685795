import React, { memo } from 'react';
import classnames from 'classnames';
import { STATUSES, FINISHED_STATUSES } from 'modules/ReservationsList/constants';
import {
  CHARGE_TYPES,
  CHARGE_TYPES_NAME,
  PAYMENT_STATUS,
  REFUND_CHARGE_TYPES,
} from 'constants/charge';
import ReservationPaymentIcon from 'components/shared/ReservationPaymentIcon';
import CurrencyFormatter from 'components/shared/CurrencyFormatter';
import type { DepositPayableFee, PaymentSummary } from 'modules/ReservationsList/types';
import useReservationPaymentOptions from 'components/shared/Payments/hooks/useReservationPaymentOptions';
import './ReservationPayment.style.scss';

interface ReservationPaymentProps {
  isAttentionReservation?: boolean;
  payableFee?: DepositPayableFee | null;
  paymentSummary?: PaymentSummary | null;
  billAmount?: string | number;
  status?: string;
  hasSupplements?: boolean;
  hasDepositPayableFee: boolean;
  text?: string;
  className?: string;
}

const ReservationPayment = ({
  hasDepositPayableFee,
  payableFee,
  paymentSummary,
  billAmount,
  status,
  hasSupplements = false,
  text = '',
  isAttentionReservation = false,
  className,
}: ReservationPaymentProps) => {
  const { payableType = CHARGE_TYPES.supplement, paymentStatus } = payableFee || {};
  const { isPaymentCharged, isPaymentPending, isPaymentFailed, chargeText, amount } =
    useReservationPaymentOptions({
      hasSupplements,
      hasDepositPayableFee,
      text,
      payableFee,
      paymentSummary,
    });
  // has a reservation payment attached with payableFee id or has more then one supplements attached
  const hasReservationPayment = (hasDepositPayableFee && payableFee?.id) || hasSupplements;
  const isFinishStatus = status?.toLowerCase() === STATUSES.FINISH.toLowerCase();

  const renderReservationIconAndText = () => (
    <span
      className={classnames('charge-icon-and-text', {
        'payment-charged': isPaymentCharged,
        'payment-pending': isPaymentPending,
      })}
    >
      <ReservationPaymentIcon
        type={payableType}
        payableFee={payableFee}
        text={text}
        isAttentionReservation={isAttentionReservation}
        isPaymentFailed={isPaymentFailed}
      />
      {chargeText}
    </span>
  );

  const renderBillAmount = (billAmountText: any, hasPayment: any) => {
    let paymentText = null;

    if (hasPayment) {
      paymentText = (
        <>
          {' '}
          with {CHARGE_TYPES_NAME[payableType]} of <CurrencyFormatter amount={amount} />
        </>
      );
    }

    if (text) {
      return (
        <>
          Reservation has a bill amount of <CurrencyFormatter amount={billAmountText} />
          {paymentText}
        </>
      );
    }

    return <CurrencyFormatter amount={billAmountText} />;
  };

  const renderReservationPayment = () => {
    if (
      isFinishStatus &&
      billAmount &&
      hasReservationPayment &&
      REFUND_CHARGE_TYPES.includes(payableType) &&
      paymentStatus === PAYMENT_STATUS.success
    ) {
      // On finished reservation status only show bill amount + successful reservation payment made for deposit and supplements
      return renderBillAmount(billAmount, true);
    }

    if (hasReservationPayment && !(isFinishStatus && billAmount)) {
      // On reservation which has a payment and its NOT a finish status with a bill amount, then show payment icon and text
      // NOTE: `!(isFinishStatus && billAmount)` condition is used for the case where a reservation has a cancellation fee, but was not charged
      // so when marked finished status with a bill amount, it should NOT render payment icon and text but rather bill amount using below if condition
      return renderReservationIconAndText();
    }

    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (FINISHED_STATUSES.includes(status?.toUpperCase()) && billAmount) {
      // Show bill amount for all the finish statuses if the billAmount is present
      // Check jira activities for test case https://umai.atlassian.net/browse/UP-3907 for more details
      return renderBillAmount(billAmount, false);
    }

    return null;
  };

  const reservationPayment = renderReservationPayment();

  return reservationPayment ? (
    <span
      aria-label="reservation payment"
      className={classnames('reservation-charge-icon', className, {
        'is-attention': isAttentionReservation,
      })}
    >
      {reservationPayment}
    </span>
  ) : null;
};

export default memo(ReservationPayment);
