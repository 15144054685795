import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface NotifyIconProps {
  width?: number;
  height?: number;
  color?: string;
  title?: string;
}

function NotifyIcon({ width = 20, height, color = COLORS.WHITE, title }: NotifyIconProps) {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20">
      {title && <title>{title}</title>}
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M10.046 20a2.966 2.966 0 01-2.926-2H1.653a.93.93 0 01-.969-.79L.5 15.176a.822.822 0 01.409-.763l1.221-.753a1.15 1.15 0 00.519-.731l1.176-5.648c.361-2.722 1.7-4.432 4-5.08A1.884 1.884 0 018.835.3a2.5 2.5 0 012.391 0 1.884 1.884 0 011.015 1.9c2.288.653 3.635 2.372 4 5.108l1.171 5.621a1.149 1.149 0 00.519.731l1.217.753a.827.827 0 01.441.763l-.181 2.034a.932.932 0 01-.97.79h-5.467a2.966 2.966 0 01-2.925 2zm-1.874-2a2.008 2.008 0 001.84 1.089v-.018h.007A2.013 2.013 0 0011.857 18H8.172zM9.991.946c-.164 0-.327.03-.481.088a1.02 1.02 0 00-.694.954c.002.129.03.255.084.372a.4.4 0 01-.025.374.51.51 0 01-.337.23l-.378.1c-1.9.532-3.027 2-3.338 4.354l-1.184 5.674a2.008 2.008 0 01-.954 1.308l-1.184.736.171 1.957h16.68l.161-1.939-1.192-.735a2.018 2.018 0 01-.948-1.307l-1.178-5.649c-.315-2.376-1.44-3.85-3.342-4.381-.1-.028-.24-.07-.384-.1a.51.51 0 01-.34-.231.4.4 0 01-.023-.377.973.973 0 00.088-.363v-.023a1.028 1.028 0 00-.55-.873c-.2-.111-.424-.17-.652-.169z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={color} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

NotifyIcon.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
};

export default NotifyIcon;
