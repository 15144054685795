import React from 'react';
import classnames from 'classnames';
import { COLORS } from 'constants/colors';
import { useIncomingCallActions } from 'modules/IncomingCall/hooks/useIncomingCallActions';
import type { IncomingCall } from 'modules/IncomingCall/types';
import ReservedIcon from 'components/icons/statuses/ReservedIcon';
import AddReservationIcon from 'components/icons/AddReservationIcon';
import PersonIcon from 'components/icons/PersonIcon';
import EditIcon from 'components/icons/EditIcon';
import CreateGuestIcon from 'components/icons/CreateGuestIcon';
import styles from './Footer.module.scss';

type Props = {
  upcomingReservation: IncomingCall['upcomingReservation'];
  callPhoneNumber: IncomingCall['callPhoneNumber'];
  guestId: number | undefined;
  callback: () => void;
};

function ReservationFooter({ upcomingReservation, callPhoneNumber, guestId, callback }: Props) {
  const {
    isFetchingGuest,
    isFetchingReservation,
    handleViewGuest,
    handleCreateGuest,
    handleViewReservation,
    handleAddReservation,
    handleEditReservation,
  } = useIncomingCallActions({
    reservationId: upcomingReservation?.id,
    slotStartTime: upcomingReservation?.slotStartTime,
    reservationStatus: upcomingReservation?.status,
    firstName: upcomingReservation?.firstName,
    lastName: upcomingReservation?.lastName,
    phone: callPhoneNumber,
    guestId,
    callback,
  });

  return (
    <>
      <button
        type="button"
        className={classnames(styles.action, { [styles.active]: !upcomingReservation })}
        onClick={handleAddReservation}
        aria-label="Add reservation"
        disabled={isFetchingGuest}
      >
        <AddReservationIcon color={!upcomingReservation ? COLORS.WHITE : COLORS.BLACK} />
        <span className={styles.label}>
          {isFetchingGuest ? 'Loading guest...' : 'Add reservation'}
        </span>
      </button>
      {upcomingReservation && (
        <button
          type="button"
          className={classnames(styles.action, styles.active)}
          onClick={handleEditReservation}
          aria-label="Edit reservation"
          disabled={isFetchingReservation}
        >
          <EditIcon
            width={20}
            height={20}
            color={isFetchingReservation ? COLORS.BLACK : COLORS.WHITE}
          />
          <span className={styles.label}>
            {isFetchingReservation ? 'Loading data...' : 'Edit reservation'}
          </span>
        </button>
      )}
      {upcomingReservation && (
        <button
          type="button"
          className={styles.action}
          onClick={handleViewReservation}
          aria-label="View reservation"
        >
          <ReservedIcon width={16} color={COLORS.BLACK} />
          <span className={styles.label}>View reservation</span>
        </button>
      )}
      {!upcomingReservation && !guestId && (
        <button
          type="button"
          className={styles.action}
          onClick={handleCreateGuest}
          aria-label="Create guest"
        >
          <CreateGuestIcon />
          <span className={styles.label}>Create guest</span>
        </button>
      )}
      {!upcomingReservation && guestId && (
        <button
          type="button"
          className={styles.action}
          onClick={handleViewGuest}
          aria-label="View guest"
        >
          <PersonIcon width={16.67} height={20} title="View Guest" />
          <span className={styles.label}>View guest</span>
        </button>
      )}
    </>
  );
}

export default ReservationFooter;
