import React from 'react';
import type {
  UpcomingQueue,
  UpcomingReservation,
  UpcomingWaitlist,
} from 'modules/IncomingCall/types';
import { getCallDateTime, getSlotEndTime } from 'modules/IncomingCall/services';
import TableIcon from 'components/icons/TableIcon';
import PersonIcon from 'components/icons/PersonIcon';
import styles from './Content.module.scss';

function Details({
  startTimeWithDate,
  endTimeWithDate,
  dateTimeStart,
  slotStartTime,
  turnTime,
  tables,
  guestCount,
}: {
  startTimeWithDate?: UpcomingWaitlist['startTimeWithDate'];
  endTimeWithDate?: UpcomingWaitlist['endTimeWithDate'];
  dateTimeStart?: UpcomingQueue['dateTimeStart'];
  slotStartTime?: UpcomingReservation['slotStartTime'];
  turnTime?: UpcomingReservation['turnTime'];
  tables?: UpcomingReservation['tables'];
  guestCount: number;
}) {
  const reservationTables = tables?.map((t) => t.name).join(',');

  const renderTime = () => {
    //  waitlist
    if (startTimeWithDate && endTimeWithDate) {
      return getCallDateTime(startTimeWithDate, endTimeWithDate);
    }

    // queue
    if (dateTimeStart) {
      return getCallDateTime(dateTimeStart);
    }

    // reservation
    // NOTE: turnTime can be 0 too, so cant check on its value
    if (slotStartTime && typeof turnTime === 'number') {
      return getCallDateTime(slotStartTime, getSlotEndTime({ slotStartTime, turnTime }));
    }

    return null;
  };

  return (
    <div className={styles.details}>
      <time>{renderTime()}</time>
      <span>
        <PersonIcon width={13} height={14} />
        <span>{guestCount}</span>
      </span>
      {reservationTables?.length ? (
        <span>
          <TableIcon width={14} height={14} />
          <span>{reservationTables}</span>
        </span>
      ) : null}
    </div>
  );
}

export default Details;
