import startCase from 'lodash-es/startCase';

const isPartySizesSelected = (partySize = {}) =>
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  Object.values(partySize).some((size = []) => size.length > 0);

export const checkIsFiltersAdded = ({
  status = [],
  room = [],
  partySize = {},
  tags,
  notes,
}: any) => {
  const isFiltersAdded =
    status.length > 0 ||
    room.length > 0 ||
    isPartySizesSelected(partySize) ||
    tags === true ||
    notes === true;
  return isFiltersAdded;
};

export const isAllSubStatusSelected = (subStatuses: any, reservationStatuses: any) =>
  subStatuses.every((status: any) => reservationStatuses.includes(status));

export const filterText = (isAllSelected: any, filters: any, extraText = '') => {
  if (isAllSelected) {
    return 'All';
  }
  if (filters.length === 1) {
    return `${filters[0]} ${extraText}`;
  }
  if (filters.length > 1) {
    return `${filters[0]} ${extraText} and ${filters.length - 1} more`;
  }
  return 'All';
};

export const partSizeFilterText = (selectedPartySizes: any) => {
  const { smallPartySize = [], bigPartySize = [], customPartySize = [] } = selectedPartySizes;
  if (!!smallPartySize.length && !!bigPartySize.length) {
    return 'All';
  }
  if (customPartySize.length) {
    return 'Custom';
  }
  if (smallPartySize.length) {
    return 'Small (1 - 5 Parties)';
  }
  if (bigPartySize.length) {
    return 'Big (6+ Parties)';
  }
  return 'All';
};

export const getAppliedFilters = (reservationFilters = {}) => {
  const appliedFilters: any = [];
  // only add filters that are selected
  Object.entries(reservationFilters).forEach(([key, value]) => {
    // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    if (key === 'partySize' && isPartySizesSelected(value)) {
      appliedFilters.push('partySize');
    }
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (value === true || value.length) {
      appliedFilters.push(key);
    }
  });
  return appliedFilters;
};

export const getAppliedFiltersName = (filters = []) => {
  const filtersName = filters.map((filter) => startCase(filter));
  // Using regex to make the last seperator 'and'. example statues, partysizes and tags
  return filtersName.join(', ').replace(/, ([^,]*)$/, ' and $1');
};

export const checkIsGuestCountMatched = (guestCount: any, selectedPartySizes: any) => {
  const { smallPartySize = [], bigPartySize = [], customPartySize = [] } = selectedPartySizes;
  // the order is important, if both big and small partySizes are selected, return all else start with custom party size
  if (smallPartySize.length && bigPartySize.length) return true;
  if (customPartySize.length && smallPartySize.length) {
    // if small partySize and custom are selected, for example, small partySize and 7 parties
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount) || guestCount <= 5;
  }
  if (customPartySize.length && bigPartySize.length) {
    // if big partySize and custom are selected, for example, big partySize and 3 parties
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount) || guestCount >= 6;
  }
  if (customPartySize.length) {
    // if 10+ partySize is selected, then return all partySizes that are more than or equal 10
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount);
  }
  if (smallPartySize.length) return guestCount <= 5;
  if (bigPartySize.length) return guestCount >= 6;
  return true;
};
