import React from 'react';
import { SkeletonLoader } from '@umai/common';
import './Sidebar.styles.scss';

function SidebarLoader() {
  const renderSidebarItem = ({ index, showSpan = true }: any = {}) => (
    <li key={index}>
      <div className="sidebar-item">
        <SkeletonLoader.Skeleton
          style={{ width: '40px', height: '40px' }}
          baseColor="#06031a"
          highlightColor="#ebebeb"
          circle
        />
        {showSpan && (
          <SkeletonLoader.Skeleton
            style={{ width: '50px' }}
            baseColor="#06031a"
            highlightColor="#ebebeb"
          />
        )}
      </div>
    </li>
  );

  return (
    <nav id="webrms-sidebar">
      <section className="overflow-sidebar">
        <ul>
          <li style={{ marginTop: '10px' }}>
            <SkeletonLoader.Skeleton
              circle
              enableAnimation
              className="outlet"
              containerClassName="outlet-wrapper"
              style={{ background: '#06031a' }}
              baseColor="#06031a"
              highlightColor="#ebebeb"
            />
          </li>
          {[1, 2, 3, 4, 5, 6].map((index) => renderSidebarItem({ index }))}
        </ul>
      </section>
      <section className="fixed-sidebar">
        <ul>
          {renderSidebarItem({ index: 'notification', showSpan: false })}
          {renderSidebarItem({ index: 'help', showSpan: false })}
          {renderSidebarItem({ index: 'settings' })}
        </ul>
      </section>
    </nav>
  );
}

export default SidebarLoader;
