import React from 'react';
import AddPersonIcon from 'components/icons/AddPersonIcon';
import styles from './Referral.module.scss';

type Props = {
  rewardOffer: string | null;
};

const ReferralBanner = ({ rewardOffer }: Props) => (
  <span className={styles.referralBanner}>
    <AddPersonIcon />
    Refer a friend, earn up to {rewardOffer} per referral.
  </span>
);

export default ReferralBanner;
