import React from 'react';
import classNames from 'classnames';
import { COLORS } from 'constants/colors';
import './Loader.styles.scss';

interface CircleProps {
  size?: number | string;
  color?: string;
  className?: string;
  dataTestid?: string;
}

const Circle = ({
  size = 60,
  color = COLORS.GREEN_PRIMARY,
  className,
  dataTestid,
}: CircleProps) => (
  <div
    role="progressbar"
    className={classNames('webrms-loader', className)}
    style={{
      width: `${size}px`,
      height: `${size}px`,
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      border: `${size * 0.1}px solid ${COLORS.GREY_LIGHT}`,
      // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      borderTop: `${size * 0.1}px solid ${color}`,
    }}
    data-testid={dataTestid}
  ></div>
);

interface HorizontalProps {
  top?: string;
  color?: string;
  className?: string;
}

const Horizontal = ({ top = '33px', color, className }: HorizontalProps) => (
  <div role="progressbar" className={classNames('horizontal-loader', className)} style={{ top }}>
    <div style={{ background: color }}></div>
    <div style={{ background: color }}></div>
    <div style={{ background: color }}></div>
    <div style={{ background: color }}></div>
  </div>
);

const AppLoader = () => (
  <div role="progressbar" className="webrms-root-loader">
    <div className="webrms-root-icon">
      <img
        className="webrms-root-icon"
        alt="umai-logo"
        src={`${process.env.PUBLIC_URL}/logo.png`}
      />
    </div>
    <div className="webrms-root-animation">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

const Loader = { Circle, Horizontal, AppLoader };

export default Loader;
