import { useEffect, useRef } from 'react';
import { selectIsAuthenticated } from 'modules/Auth/selectors';
import { useAppSelector } from './redux';

// This hook listens for messages from the service worker and navigates to the path specified in the message
// Used for push notifications
const useServiceWorkerNavigation = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isListening = useRef(false);

  useEffect(() => {
    if (!isAuthenticated && !isListening.current) {
      isListening.current = true;
      navigator.serviceWorker?.addEventListener('message', (event) => {
        const { postMessageType, path } = event.data || {};
        if (postMessageType === 'navigate') {
          window.location.href = path;
        }
      });
    }
  }, [isAuthenticated]);
};

export default useServiceWorkerNavigation;
