import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useViewPortContext } from '@umai/common';
import { INCOMING_CALL_PERMISSIONS } from 'constants/operational-permission';
import { useAppSelector } from 'hooks/redux';
import useOutsideClick from 'hooks/useOutsideClick';
import { AppContext } from 'contexts/app';
import {
  selectCurrentVenueId,
  selectIsIncomingCallEnabled,
  selectIsIncomingCallSubscribed,
} from 'modules/Partner/selectors';
import { selectIsElementAccessAuthorized } from 'modules/Auth/selectors';
import { getIsInAppNotificationEnabled } from 'modules/IncomingCall/services';
import useSocketIncomingCall from 'modules/IncomingCall/hooks/useSocketIncomingCall';
import Popover from 'components/popovers/Popover';
import IncomingCallIcon from 'components/icons/IncomingCallIcon';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import styles from './CallPopup.module.scss';

export default function CallPopup() {
  const callRef = useRef<HTMLDivElement>(null);
  const { isOutSideClick, setIsOutSideClick } = useOutsideClick(callRef);
  const [open, setOpen] = useState(true);
  const { mobile } = useViewPortContext();
  const {
    callPopup: { isVisible, data },
  } = useContext(AppContext);
  const callId = data?.id;
  const isSubscribed = useAppSelector(selectIsIncomingCallSubscribed);
  const isEnabled = useAppSelector(selectIsIncomingCallEnabled);
  const currentVenueId = useAppSelector(selectCurrentVenueId);
  const isAccessAuthorized = useAppSelector(
    selectIsElementAccessAuthorized(INCOMING_CALL_PERMISSIONS)
  );

  useSocketIncomingCall();

  useEffect(() => {
    // If a new call comes in when the popup is minimized with previous call,
    // then open the call popup again to show the new call data
    if (callId) {
      setOpen(true);
    }
  }, [callId]);

  if (
    !isAccessAuthorized ||
    !isVisible ||
    !data ||
    !isEnabled ||
    !isSubscribed ||
    !getIsInAppNotificationEnabled(currentVenueId) ||
    data.venueId !== currentVenueId
  ) {
    return null;
  }

  return (
    <Popover
      triggerElement={
        <button
          type="button"
          // When the popup is opened, transition away and hide the trigger button
          className={classnames(styles.callPopupTrigger, { [styles.hideTrigger]: open })}
          aria-label="trigger incoming call"
          onClick={(event) => {
            // Manually toggle the call popup by avoiding and propagating this event.
            // So on clicking on trigger button, we can avoid closing popup which is outside of callRef
            event.preventDefault();
            event.stopPropagation();
            setIsOutSideClick(false);
            setOpen(!open);
          }}
        >
          <IncomingCallIcon width={18.33} height={18.33} />
        </button>
      }
      placement="bottom"
      open={open}
      onOpenChange={(visible) => {
        // Do not close the popup when clicked outside of the popup.
        if (visible && isOutSideClick) {
          return;
        }

        setOpen(open);
      }}
      arrow={false}
      isBackdropEnabled={false}
      align={{
        points: ['r'],
        overflow: {
          shiftY: 38, // shift to top with the height of the trigger button
          shiftX: -38, // shift to right with the width of the trigger button
        },
        offset: mobile ? [0, 8] : [-8, 8],
      }}
      rootClassName="callPopupRoot"
    >
      <div className={styles.callPopup} aria-label="incoming call popup" ref={callRef}>
        <Header data={data} setOpen={setOpen} />
        <Content data={data} />
        <Footer data={data} />
      </div>
    </Popover>
  );
}
