import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import classnames from 'classnames';
import { FUNNEL_ROUTES } from 'constants/routes';
import { AppContext } from 'contexts/app';
import { useReferral } from 'modules/Partner/hooks/useReferral';
import Alert from './Alert';
import ReferralBanner from '../Referral/ReferralBanner';
import styles from './AlertsContainer.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-in-warning
const VENUE_WARNING_CLASS = 'venue-in-warning';

interface Props {
  paymentProfileAlerts: {
    messages?: string[];
    buttonLabel: string;
    buttonAction: () => void;
    closeAction: () => void;
    isVisible: boolean;
    isReferral?: boolean;
    id: string;
  }[];
}

function AlertsContainer({ paymentProfileAlerts }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { hasSideBar, referralVisibility } = useContext(AppContext);
  const { handleCloseBanner, handleReferral, rewardOffer } = useReferral();

  const referralMessage = renderToStaticMarkup(<ReferralBanner rewardOffer={rewardOffer} />);

  const alerts = [
    {
      id: 'referral-banner',
      messages: [`${referralMessage}`],
      buttonLabel: 'Learn more',
      buttonAction: handleReferral,
      closeAction: handleCloseBanner,
      isVisible: referralVisibility.isBannerVisible,
      isReferral: true,
    },
    ...paymentProfileAlerts,
  ].filter((alert) => alert.isVisible);

  useLayoutEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach(() => {
        if (!containerRef.current) return;
        document.documentElement.style.setProperty(
          '--payment-warning-height',
          `${containerRef.current.offsetHeight}px`
        );
      });
    });

    // observer for height changes when the payment alert resizes, so that we can adjust the screen with alert height
    if (containerRef.current) {
      myObserver.observe(containerRef.current);
    }

    return () => {
      //  stop listening to changes
      myObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (alerts.length > 0) {
      bodyClassList.add(VENUE_WARNING_CLASS);
    } else {
      bodyClassList.remove(VENUE_WARNING_CLASS);
    }

    return () => {
      bodyClassList.remove(VENUE_WARNING_CLASS);
    };
  }, [alerts.length]);

  return (
    <div
      ref={containerRef}
      className={classnames(styles.alertsContainer, {
        [styles.fullWidth]:
          (FUNNEL_ROUTES as ReadonlyArray<string>).includes(location.pathname) || !hasSideBar(),
      })}
      data-testid="alerts-container"
    >
      {alerts.map(({ id, messages, buttonLabel, isReferral, buttonAction, closeAction }) => (
        <Alert
          key={id}
          messages={messages}
          buttonLabel={buttonLabel}
          onButtonClick={buttonAction}
          onClose={closeAction}
          isReferral={isReferral}
        />
      ))}
    </div>
  );
}

export default AlertsContainer;
