import React from 'react';
import {
  OPERATIONAL_PERMISSIONS,
  GUEST_LIST_OPERATIONAL_PERMISSIONS,
} from 'constants/operational-permission';
import ProtectedElement from 'components/shared/ProtectedElement';
import useBootStrap from './hooks/useBootstrap';
import useSocketUser from './hooks/useSocketUser';
import useGetIpInfo from './hooks/useGetIpInfo';
import useServiceWorkerNavigation from 'hooks/useServiceWorkerNavigation';
import BootstrapReservations from './BootstrapReservations';
import BootstrapGuests from './BootstrapGuests';

const BootstrapApp = () => {
  useBootStrap();
  useSocketUser();
  useGetIpInfo();
  useServiceWorkerNavigation();

  return (
    <>
      <ProtectedElement allowedPermission={GUEST_LIST_OPERATIONAL_PERMISSIONS}>
        <BootstrapGuests />
      </ProtectedElement>
      <ProtectedElement allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}>
        <BootstrapReservations />
      </ProtectedElement>
    </>
  );
};

export default BootstrapApp;
