import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import queueApis from 'modules/QueueList/apis';
import { incomingCallKeys } from '../services';

export default function useFetchQueue({
  queueId,
  enabled = true,
}: {
  queueId?: number;
  enabled?: boolean;
}) {
  const venueId = useAppSelector(selectCurrentVenueId);

  const {
    isFetching: isFetchingQueue,
    data: queue,
    refetch: refetchQueue,
  } = useQuery({
    queryKey: incomingCallKeys.fetchQueue({ venueId, queueId }),
    queryFn: () => {
      if (queueId) {
        return queueApis.getQueueItem({ id: queueId, venueId });
      }

      return null;
    },
    select: (data) => data,
    enabled: !!venueId && !!queueId && enabled,
  });

  return { queue, isFetchingQueue, refetchQueue };
}
