import React from 'react';
import classnames from 'classnames';
import AlertButtons from './AlertButtons';
import styles from './AlertsContainer.module.scss';

type Props = {
  onClose: () => void;
  buttonLabel: string;
  onButtonClick: () => void;
  messages?: string[];
  isReferral?: boolean;
};

const Alert = ({ onClose, buttonLabel, onButtonClick, messages, isReferral }: Props) => {
  if (!messages?.length) {
    return null;
  }

  return (
    <div className={classnames(styles.alert, { [styles.referral]: isReferral })} role="alert">
      <ul className={styles.alertMessages}>
        {messages
          ?.sort((a, b) => b.length - a.length)
          .map((alert, index) => (
            <li
              key={alert}
              className={classnames(
                styles.message,
                { [styles.referral]: isReferral },
                { [styles.firstMessage]: index === 0 }
              )}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: alert }}
            />
          ))}
      </ul>
      <AlertButtons
        buttonLabel={buttonLabel}
        isReferral={isReferral}
        onButtonClick={onButtonClick}
        onClose={onClose}
      />
    </div>
  );
};

export default Alert;
