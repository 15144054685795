import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';
import { Venue, VenueId, VenueUpdatePayload } from '../types';
import { VenueAvailabilitySummary } from 'modules/Footer/types';

class VenueApi extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  getVenueReservationAvailabilities(
    { venueId, date }: { venueId: VenueId; date: string | null },
    config: any
  ) {
    return this.fetch('/reservation_availabilities', { venueId, date }, config);
  }

  getVenue(venueId: VenueId) {
    return this.fetch(`/venues/${venueId}`);
  }

  getVenueAvailabilitySummary(
    venueId: VenueId,
    { date, signal }: { date: string | null; signal?: AbortSignal }
  ): Promise<VenueAvailabilitySummary> {
    return this.fetch(`/venues/${venueId}/footer_v2`, { date }, { signal });
  }

  getCalendarAvailability({ venueId, startDate, endDate, partySize }: any, config: any) {
    return this.fetch(`/venues/${venueId}/calendar`, { startDate, endDate, partySize }, config);
  }

  getVenueLoyaltyTiers(venueId: VenueId) {
    return this.fetch('/master_guests/loyalty_tiers', { venueId });
  }

  updateVenueData(venueId: VenueId, data: Partial<VenueUpdatePayload>) {
    return this.patch<{ venue: Venue }>(`/venues/${venueId}`, { venue: data });
  }
}

export default new VenueApi();
