import { HELP_CHAT_INTERCOM_ID, HELP_CENTER_URL } from 'constants/app';
import { OpenNewWindow } from '@umai/common';

export const openHelpCenterLink = new OpenNewWindow(HELP_CENTER_URL, HELP_CHAT_INTERCOM_ID);

export const openUmaiLink = new OpenNewWindow('https://umai.io', 'umai-powered-by-umai');

export const fileToBase64 = async (file?: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (e) => reject(e);
    } else {
      reject(new Error('file not found'));
    }
  });
