import moment from 'moment-timezone';
import { DATE_FORMAT, isIOSWebView, SERVER_DATE_TIME } from '@umai/common';
import { FINISHED_STATUSES } from 'modules/ReservationsList/constants';
import { adjustDayAfterMidNight } from 'utils/date-and-time';
import { sendTokenToServer } from './firebase-messaging';

type NotificationData = {
  number_of_guests: number;
  source_of_creation: string;
  start_date_time: string;
  status: string;
  suggestion_id: number | null;
  changes: Record<string, unknown>;
};

type PushNotificationData = {
  body: string;
  title: string;
  requireInteraction: boolean;
  venue_id: number;
  created_at: string;
  notification_id: number;
  notifiable_id: number;
  notification_type: string;
  filter_key: string;
  type: string;
  source_of_change: string;
  notification_action: string;
  notification_data: NotificationData;
  badge: number;
};

// Similar to `getNotifiableDate` function in `public/firebase-messaging-sw.js`
export function getNotifiableDate(notificationData?: { start_date_time: string }) {
  try {
    const date = notificationData?.start_date_time;
    if (date) {
      const momentDate = moment(date, SERVER_DATE_TIME);

      // Check if the date is valid
      if (!momentDate.isValid()) {
        return null;
      }

      // Subtract one day if the time is between 00:00 and 05:59
      return adjustDayAfterMidNight({
        time: momentDate,
        action: 'subtract',
      }).format(DATE_FORMAT);
    }
    return null;
  } catch (error) {
    console.error('error', error);
    return null;
  }
}

// Copy of `getPath` function in `public/firebase-messaging-sw.js`
export function getPath(data: PushNotificationData) {
  if (data.filter_key === 'GiftCard') {
    return '/giftcard-dashboard';
  }

  if (data.type === 'order') {
    return '/shop-dashboard';
  }

  const date = getNotifiableDate(data.notification_data);
  const id = data.notifiable_id;

  // Date and id are required for all reservation related notifications
  if (!date || !id) {
    return '/';
  }

  switch (data.notification_type) {
    case 'Reservation': {
      if (FINISHED_STATUSES.includes(data.notification_data.status.toUpperCase())) {
        return `/finished-reservations?date=${date}&reservationId=${id}`;
      }
      return `/reservations?date=${date}&reservationId=${id}`;
    }
    case 'Notifylist':
      return `/waitlist?date=${date}&waitlistId=${id}`;
    case 'OfflineWaitlist':
      return `/queue?date=${date}&queueId=${id}`;
    default:
      return '/';
  }
}

// Example payload:
// "{\"data\":{\"body\":\"Hendrik Lammers, 60136787352\",\"title\":\"New Reservation for Sat 02 Nov, 06:30 PM\",\"requireInteraction\":true,\"venue_id\":367,\"created_at\":\"2024-10-25T15:06:16.225+08:00\",\"notification_id\":152047,\"notifiable_id\":7856850,\"notification_type\":\"Reservation\",\"filter_key\":\"Reservation\",\"type\":\"Reservation\",\"source_of_change\":\"widget\",\"notification_action\":\"created\",\"notification_data\":{\"number_of_guests\":2,\"source_of_creation\":\"widget\",\"start_date_time\":\"2024-11-02T18:30:00.000+08:00\",\"status\":\"reserved\",\"suggestion_id\":null,\"changes\":{}},\"badge\":3}}"

// Initialize functionality needed to handle native notifications on iPhone app
export function initNativeNotifications() {
  if (isIOSWebView()) {
    // This function is called by native iOS app when a push notification is received
    window.receiveNativeNotification = (payload: string) => {
      try {
        const { data } = JSON.parse(payload) as { data: PushNotificationData };
        const path = getPath(data);
        window.location.href = path;
      } catch (err) {
        console.error(err);
      }
    };

    // This function is called from the native iPhone app
    // Used to send native device token to the server
    window.setNativeToken = (token: string) => {
      window.localStorage.setItem('nativeToken', token);
      sendTokenToServer(token);
    };

    // Let native app know that the webview is ready to receive the token
    window.webkit?.messageHandlers?.setNativeToken?.postMessage?.(true);
  }
}
