import { AxiosRequestConfig } from 'axios';
import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import { VenueId } from 'modules/Venue/types';
import HttpRequest from 'utils/http-request';
import type { ExportReservation, Reservation as IReservation } from './types';

class Reservation extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/reservations`);
  }

  getCurrentReservations({ date, allDay, venueId }: any, config: AxiosRequestConfig) {
    return this.fetch('/optimised_list', { date, allDay, venueId }, config);
  }

  getFinishedReservations({ date, allDay, venueId }: any, config: AxiosRequestConfig) {
    return this.fetch('/optimised_finished_list', { date, allDay, venueId }, config);
  }

  getReservation({ id, venueId }: { id: number; venueId: VenueId }) {
    return this.fetch<{ reservation: IReservation }>(`/${id}`, { venueId });
  }

  editReservation(data: any, id: any) {
    return this.update(`/${id}`, data);
  }

  updateReservationStatus(data: any, id: any) {
    return this.update(`/${id}/update_status`, data);
  }

  updateReservationStatusWithCharge(data: any, id: any) {
    return this.update(`/${id}/cancel`, data);
  }

  updateReservationInfo(data: any, id: any) {
    return this.update(`/${id}/update_reservation_info`, data);
  }

  getCalendarReservations({ venueId, startDate, endDate, bookedOut }: any) {
    return this.fetch('/calendar_v2', { venueId, startDate, endDate, bookedOut });
  }

  getReservationActivities(id: any, venueId: any, config: AxiosRequestConfig = {}) {
    return this.fetch(`/${id}/activities`, { venueId }, config);
  }

  sendEmailConfirmation(id: any, venueId: any) {
    return this.fetch(`/${id}/send_confirmation_email`, { venueId });
  }

  swapTableAndTime(params: any) {
    return this.update('/swap_tables', params);
  }

  exportReservations(params: ExportReservation, config: AxiosRequestConfig) {
    return this.fetch<{ data: Blob }>('/export.pdf', params, config);
  }

  resendManualPaymentLink(id: number, venueId: number) {
    return this.fetch(`/${id}/resend_payment_link`, { venueId });
  }
}

export default new Reservation();
