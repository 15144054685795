import React from 'react';
import { CHARGE_TYPES, CHARGE_TYPES_NAME, PAYMENT_STATUS } from 'constants/charge';
import CurrencyFormatter from 'components/shared/CurrencyFormatter';
import { DepositPayableFee, PaymentSummary } from 'modules/ReservationsList/types';

const useReservationPaymentOptions = ({
  hasDepositPayableFee,
  hasSupplements,
  payableFee,
  text = '',
  paymentSummary,
}: {
  hasDepositPayableFee: boolean;
  hasSupplements: boolean;
  payableFee?: Partial<DepositPayableFee> | null;
  text?: string;
  paymentSummary?: Partial<PaymentSummary> | null;
}) => {
  const { payableType, paymentStatus = '' } = payableFee || {};
  // The following conversion to number are needed because backend api send the below amounts in strings,
  // like "5.0" or "0.0", so if refundAmount is "0.0" it would convert to 0, which means no refund
  const totalAmount = +(payableFee?.totalAmount || 0);
  const depositAmount = +(paymentSummary?.totalAmount || payableFee?.depositAmount || 0);
  const cancelAmount = +(paymentSummary?.totalCancellationAmount || payableFee?.cancelAmount || 0);
  const refundAmount = +(paymentSummary?.totalRefundAmount || payableFee?.refundAmount || 0);
  const payableAmount = +(payableFee?.payableAmount || 0);

  const renderAmount = (amount: any) => <CurrencyFormatter amount={amount || 0} />;

  // for reservations with no payment and amount with a supplements, then it should be free,
  // NOTE: its used for supplements items with 0 price, which are considered free reservation
  if (hasSupplements && !hasDepositPayableFee) {
    return {
      isPaymentCharged: false,
      isPaymentPending: false,
      isPaymentFailed: false,
      chargeText: `Free ${CHARGE_TYPES_NAME[CHARGE_TYPES.supplement]}`,
      amount: 0,
    };
  }

  // get the relevant payment options on reservation depending on the payableType
  switch (payableType) {
    case CHARGE_TYPES.supplement:
    case CHARGE_TYPES.deposit: {
      let refundOptions = {};
      const amountCalculated =
        payableType === CHARGE_TYPES.supplement
          ? totalAmount
          : // this is cross check  if depositAmount is null
            depositAmount || payableAmount;

      if (paymentStatus === PAYMENT_STATUS.refund) {
        const refundAmountCalculated = refundAmount || payableAmount; // this is cross check if refundAmount is null or "0.0" from api

        refundOptions = {
          isPaymentCharged: true,
          isPaymentPending: false,
          isPaymentFailed: false,
          chargeText: <>Refunded {renderAmount(refundAmountCalculated)}</>,
          amount: refundAmountCalculated,
        };
      }

      if (paymentStatus === PAYMENT_STATUS.success) {
        refundOptions = {
          isPaymentCharged: false,
          isPaymentPending: false,
          isPaymentFailed: false,
          chargeText: text ? (
            <>Made a reservation payment of {renderAmount(amountCalculated)}</>
          ) : (
            renderAmount(amountCalculated)
          ),
          amount: amountCalculated,
        };
      }

      if (paymentStatus === PAYMENT_STATUS.pending) {
        let pendingText = text ? (
          <>Pending payment for reservation of {renderAmount(amountCalculated)}</>
        ) : (
          <>Pending {renderAmount(amountCalculated)}</>
        );

        if (refundAmount) {
          pendingText = text ? (
            <>Refund of {renderAmount(refundAmount)} is currently pending</>
          ) : (
            <>Pending refund {renderAmount(refundAmount)}</>
          );
        }

        refundOptions = {
          isPaymentCharged: false,
          isPaymentPending: true,
          isPaymentFailed: false,
          chargeText: pendingText,
          // if there is a refund pending the need to show the refund amount
          amount: refundAmount || amountCalculated,
        };
      }

      return {
        isPaymentCharged: true,
        isPaymentPending: false,
        isPaymentFailed: true,
        chargeText: text ? (
          <>
            The payment of {renderAmount(amountCalculated)} has failed. {text}
          </>
        ) : (
          <>Failed {renderAmount(amountCalculated)}</>
        ),
        amount: amountCalculated,
        ...refundOptions,
      };
    }

    case CHARGE_TYPES.cancellation: {
      let cancelledOptions = {};

      if (paymentStatus === PAYMENT_STATUS.success) {
        cancelledOptions = {
          isPaymentCharged: true,
          isPaymentPending: false,
          isPaymentFailed: false,
          chargeText: <>Charged {renderAmount(cancelAmount)}</>,
          amount: cancelAmount,
        };
      }

      if (!paymentStatus || paymentStatus === 'free') {
        cancelledOptions = {
          isPaymentCharged: false,
          isPaymentPending: false,
          isPaymentFailed: false,
          chargeText: text ? (
            <>Reservation has a cancellation fee of {renderAmount(payableAmount)}</>
          ) : (
            renderAmount(payableAmount)
          ),
          amount: payableAmount,
        };
      }

      if (paymentStatus === PAYMENT_STATUS.pending) {
        cancelledOptions = {
          isPaymentCharged: false,
          isPaymentPending: true,
          isPaymentFailed: false,
          chargeText: text ? (
            <>
              Pending payment for reservation with a cancellation fee of{' '}
              {renderAmount(payableAmount)}
            </>
          ) : (
            <>Pending {renderAmount(payableAmount)}</>
          ),
          amount: payableAmount,
        };
      }

      return {
        isPaymentCharged: true,
        isPaymentPending: false,
        isPaymentFailed: true,
        chargeText: text ? (
          <>
            The payment of {renderAmount(payableAmount)} amount has failed. {text}
          </>
        ) : (
          <>Failed {renderAmount(payableAmount)}</>
        ),
        amount: payableAmount,
        ...cancelledOptions,
      };
    }

    default:
      return {
        isPaymentCharged: false,
        isPaymentPending: false,
        isPaymentFailed: false,
        chargeText: '',
        amount: 0,
      };
  }
};

export default useReservationPaymentOptions;
