import moment from 'moment-timezone';
import {
  WAITLIST_TIME_INTERVAL,
  ALL_DAY_AVAILABILITY,
  WAITLIST_STATUSES,
} from 'modules/ReservationsList/constants';
import { DATE_FORMAT } from 'constants/time-and-date';
import { FINISHED_WAITLIST_AVAILABILITY } from './constants';
import { checkStringMatch } from 'utils/string-helpers';
import { adjustDayAfterMidNight, formatDateToServerDate } from 'utils/date-and-time';
import { combineArrays, combineTagArrays } from 'utils/array-helpers';
import { getReservationGuest, getReservationGuestName } from 'modules/ReservationsList/services';
import { checkIsGuestCountMatched } from 'modules/Filter/services';
import type {
  ApplyWaitlistFiltersProps,
  PartySizesFilter,
  Waitlist,
  WaitListItemSlot,
  WaitlistStatus,
} from './types';

// Returns date with minutes rounded to the next time interval
const defaultWaitlistStartTime = (selectedDate: any) => {
  const waitlistDate = moment(selectedDate, DATE_FORMAT).set({
    hour: moment().get('hour'),
    minute: moment().get('minute'),
  });

  const time = adjustDayAfterMidNight({
    time: waitlistDate,
  });

  const remainder = WAITLIST_TIME_INTERVAL - (time.minute() % WAITLIST_TIME_INTERVAL);
  return moment(time).add(remainder, 'minutes');
};

export const getWaitlistPopupTime = (time: any, selectedDate: any) => {
  if (time) {
    const mTime = moment(time);
    const waitlistDate = moment(selectedDate, DATE_FORMAT).set({
      hour: mTime.get('hour'),
      minute: mTime.get('minute'),
      second: 0,
      millisecond: 0,
    });

    // After midnight until 5:59 AM, we always retrieve the availability of the previous day to obtain slots that occur after midnight.
    // Therefore, we need to add a day to the selectedDate because the waitlist time needs to match the regular time
    // in order to correspond with the slot time that needs to be sent to the backend.

    return adjustDayAfterMidNight({
      time: waitlistDate,
    });
  }
  return defaultWaitlistStartTime(selectedDate);
};

const filterBySearchText = (waitlists: Waitlist[], strToSearch = '') => {
  // remove extra whitespaces
  const searchText = String(strToSearch)?.toLowerCase()?.trim();
  if (!searchText?.length) {
    return waitlists;
  }

  return waitlists.filter((waitlist: any) => {
    const isStringMatched = checkStringMatch(
      [...Object.values(getReservationGuest(waitlist)), getReservationGuestName(waitlist)],
      searchText
    );

    return isStringMatched;
  });
};

const filterByStatuses = (waitlists: Waitlist[], statusFilters: Uppercase<WaitlistStatus>[]) => {
  // Don't perform any search if no status is selected or the slot is not available
  if (!statusFilters.length) {
    return waitlists;
  }

  return waitlists.filter(({ status }) =>
    statusFilters.includes(status.toUpperCase() as Uppercase<WaitlistStatus>)
  );
};

const filterByPartySizes = (waitlists: Waitlist[], partySizeFilters: PartySizesFilter) =>
  waitlists.filter((reservation: any) => {
    const { guestCount } = reservation;
    const isGuestCountMatched = checkIsGuestCountMatched(guestCount, partySizeFilters);

    return isGuestCountMatched;
  });

const filterByNotes = (waitlists: Waitlist[], notesFilter: boolean) => {
  // Note filter is always false by default
  if (!notesFilter) {
    return waitlists;
  }

  return waitlists.filter((waitlist: Waitlist) => {
    const { notes, masterGuest } = waitlist;
    const { guestNote } = masterGuest;
    // We filtering both the visit and guest notes
    const isNoteSelected = !!notes || !!guestNote?.note;

    return isNoteSelected;
  });
};

const filterByTags = (waitlists: Waitlist[], tagsFilter: boolean) => {
  if (!tagsFilter) {
    return waitlists;
  }

  return waitlists.filter((waitlist: Waitlist) => {
    const { tags, reservationTags, masterGuest } = waitlist;
    const { tags: guestTags = [], automatedTags: automatedGuestTags = [] } = masterGuest || {};

    const combinedGuestTags = combineTagArrays({ autoTags: automatedGuestTags, tags: guestTags });
    const combinedRsvTags = combineTagArrays({
      autoTags: [],
      // combining arrays to merge (intersect) both set of arrays
      tags: combineArrays(tags, reservationTags),
    });
    // We filtering both the visit and guest tags
    const isTagsSelected = !!combinedGuestTags.length || !!combinedRsvTags.length;

    return isTagsSelected;
  });
};

export const applyWaitlistFilter = ({
  waitlistItemsSlots,
  availabilityId,
  searchText,
  waitlistFilters,
}: ApplyWaitlistFiltersProps) => {
  const { status = [], partySize = {}, notes = false, tags = false } = waitlistFilters;

  const filteredSlots = waitlistItemsSlots.map((WaitListItemSlot: WaitListItemSlot) => {
    const { list = [] } = WaitListItemSlot;

    if (!list?.length) return WaitListItemSlot;

    const filters: { fn: (list: Waitlist[], args: any) => Waitlist[]; args: any }[] = [
      { fn: filterBySearchText, args: searchText },
      { fn: filterByStatuses, args: status },
      { fn: filterByPartySizes, args: partySize },
      { fn: filterByNotes, args: notes },
      { fn: filterByTags, args: tags },
      // ... (other filters)
    ];

    return {
      ...WaitListItemSlot,
      list: filters.reduce((result, filter) => filter.fn(result, filter.args), list),
    };
  });

  return filteredSlots.filter(
    ({
      list,
      reservationAvailabilityId,
    }: {
      list: Waitlist[];
      reservationAvailabilityId: string | null;
    }) => {
      if (availabilityId !== ALL_DAY_AVAILABILITY)
        return reservationAvailabilityId === availabilityId;
      return list.length;
    }
  );
};

// get total number of items in waitlist, excluding finished (reservationAvailabilityId === null)
export const getWaitlistNotificationCount = ({ availabilities }: any = {}) =>
  Object.values(availabilities || {})?.reduce(
    // @ts-expect-error TS(2769): No overload matches this call.
    (total, { list, reservationAvailabilityId }) =>
      reservationAvailabilityId && reservationAvailabilityId !== FINISHED_WAITLIST_AVAILABILITY.id
        ? total + (list?.length || 0)
        : total,
    0
  );

export const getWaitlistCount = (waitlist: WaitListItemSlot[]): number =>
  waitlist.reduce((total: number, { list }: WaitListItemSlot) => total + (list?.length || 0), 0);

export const getWaitlistGuestCount = (lists: WaitListItemSlot[]): number => {
  let count = 0;
  if (lists.length) {
    const reducer = (accumulator: number, { list }: WaitListItemSlot) =>
      accumulator + list.reduce((acc: number, item: Waitlist) => acc + (item?.guestCount || 0), 0);
    count = lists.reduce(reducer, 0);
  }
  return count;
};

export const formatWaitlistTime = (startTime: any, endTime: any) =>
  moment(startTime).isSame(moment(endTime), 'minute')
    ? moment(startTime).format('LT')
    : `${moment(startTime).format('LT')} - ${moment(endTime).format('LT')}`;

export const getIsExpiredWaitlist = (status: any) =>
  status?.toUpperCase() === WAITLIST_STATUSES.EXPIRED_NOTIFY;

export const getWaitlistCacheKey = ({ venueId, date }: any) =>
  `${venueId}-${formatDateToServerDate(date)}`;
